import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { LinkContainer } from "react-router-bootstrap";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useAuth } from "../../layout/AuthProvider";
import { toReadableDate } from "../../../utils/toReadableDate";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { toBirthDay } from "../../../utils/toBirthDay";
import { pagePrefix } from "../../../utils/pagePrefix";

const OldConsults = () => {
  const { isLoggedIn } = useAuth();
  const [consults, setConsults] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [patientNameFilter, setPatientNameFilter] = useState(null);
  const [organizationFilter, setOrganizationFilter] = useState(null);
  const [consultantFilter, setConsultantFilter] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [consultants, setConsultants] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`/api/v1/consults/old`, { withCredentials: true })
      .then((res) => {
        if (res.status === 200) {
          setConsults(sortConsultsInReverseChronologicalOrder(res.data));
        }
      })
      .catch((error) => {
        console.error("Failed to get old consults", error);
      });
  }, []);

  const sortConsultsInReverseChronologicalOrder = (data) => {
    return data.sort((a, b) => {
      return new Date(b.closedAt) - new Date(a.closedAt);
    });
  };

  useEffect(() => {
    if (consults && consults.length > 0) {
      let orgs = [];
      let orgIds = [];
      consults.forEach((consult) => {
        if (!orgIds.includes(consult.callup_organization.id)) {
          orgIds.push(consult.callup_organization.id);
          orgs.push(consult.callup_organization);
        }
      });
      setOrganizations(orgs);

      let cons = [];
      let conIds = [];
      consults.forEach((consult) => {
        let closedByRoleUser = consult.closedByUser;
        if (
          closedByRoleUser &&
          closedByRoleUser.type === "consultant" &&
          !conIds.includes(closedByRoleUser.id)
        ) {
          conIds.push(closedByRoleUser.id);
          cons.push(closedByRoleUser);
        }
        let modifiedByUsers = consult.callup_note
          ? consult.callup_note.modifiedByUsers
          : null;
        if (modifiedByUsers) {
          modifiedByUsers.forEach((user) => {
            if (user.type === "consultant" && !conIds.includes(user.id)) {
              conIds.push(user.id);
              cons.push(user);
            }
          });
        }
        let comments = consult.callup_comments;
        if (comments && comments.length > 0) {
          comments.forEach((comment) => {
            let user = comment.callup_user;
            if (
              user &&
              user.type === "consultant" &&
              !conIds.includes(user.id)
            ) {
              conIds.push(user.id);
              cons.push(user);
            }
          });
        }
      });
      setConsultants(cons);
    }
  }, [consults]);

  const filterConsults = (cs) => {
    if (statusFilter && statusFilter !== "") {
      cs = cs.filter((c) => c.status === statusFilter);
    }
    if (patientNameFilter && patientNameFilter !== "") {
      cs = cs.filter((c) => {
        let name = c.callup_patient.firstName + " " + c.callup_patient.lastName;
        if (name.toLowerCase().includes(patientNameFilter.toLowerCase())) {
          return true;
        }
        return false;
      });
    }
    if (organizationFilter && organizationFilter !== "") {
      cs = cs.filter(
        (c) => c.callup_organization.id === Number(organizationFilter)
      );
    }
    if (consultantFilter && consultantFilter !== "") {
      let cf = Number(consultantFilter);
      cs = cs.filter(
        (c) =>
          c.createdByRole.callup_user.id === cf ||
          c.closedByUser.id === cf ||
          (c.callup_note &&
            c.callup_note.modifiedByUsers &&
            c.callup_note.modifiedByUsers.some((u) => u.id === cf)) ||
          (c.callup_comments &&
            c.callup_comments.some((comment) => comment.callup_user.id === cf))
      );
    }
    return cs;
  };

  const handleCreateNewConsult = (consult) => {
    navigate(`${pagePrefix}/consult/new`, {
      state: {
        patientId: consult.callup_patient.id,
        organizationId: consult.callup_organization.id,
        callerId: consult.createdByRole.callup_user.id,
      },
    });
  };

  const renderTableOfOldConsults = () => {
    if (!consults) {
      return <p>No old consults</p>;
    }
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Consult ID</th>
            <th>Patient Name</th>
            <th>Patient Age/DOB</th>
            <th>Reqester Name</th>
            <th>Requesting Organization</th>
            <th>Closing Date</th>
            <th>Consult Status</th>
            <th>Link</th>
            <th>Create New Consult</th>
          </tr>
        </thead>
        <tbody>
          {filterConsults(consults).map((consult) => (
            <tr key={consult.id}>
              <td>{consult.id}</td>
              <td>
                {consult.callup_patient.firstName}{" "}
                {consult.callup_patient.lastName}
              </td>
              <td>
                Age: {consult.callup_patient.age} <br />
                {consult.callup_patient.DOB
                  ? toBirthDay(consult.callup_patient.DOB)
                  : null}
              </td>
              <td>
                {consult.createdByRole.callup_user.firstName}{" "}
                {consult.createdByRole.callup_user.lastName}
              </td>
              <td>{consult.callup_organization.name}</td>
              <td>{toReadableDate(consult.closedAt)}</td>
              <td>{capitalizeFirstLetter(consult.status)}</td>
              <td>
                <LinkContainer to={`${pagePrefix}/consult/${consult.id}`}>
                  <Button variant="primary" size="sm">
                    View Consult
                  </Button>
                </LinkContainer>
              </td>
              <td>
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => handleCreateNewConsult(consult)}
                >
                  Create New Consult
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const renderFilters = () => {
    return (
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Filter by Patient Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Patient Name"
            onChange={(e) => setPatientNameFilter(e.target.value)}
          />
        </Form.Group>
        <Row>
          <Col sm={2}>
            <Form.Group className="mb-3">
              <Form.Label>Filter by Status</Form.Label>
              <Form.Select
                aria-label="Filter by Status"
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <option value="">All</option>
                <option value="closed">Closed</option>
                <option value="cancelled">Cancelled</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm={5}>
            <Form.Group className="mb-3">
              <Form.Label>Filter by Organization</Form.Label>
              <Form.Select
                aria-label="Filter by Organization"
                onChange={(e) => setOrganizationFilter(e.target.value)}
              >
                <option value="">All</option>
                {organizations &&
                  organizations.map((org) => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </Col>
          {isLoggedIn === "consumer" ? null : (
            <Col sm={5}>
              <Form.Group className="mb-3">
                <Form.Label>Filter by Consultant</Form.Label>
                <Form.Select
                  aria-label="Filter by Consultant"
                  onChange={(e) => setConsultantFilter(e.target.value)}
                >
                  <option value="">All</option>
                  {consultants &&
                    consultants.map((con) => (
                      <option key={con.id} value={con.id}>
                        {con.firstName} {con.lastName}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
          )}
        </Row>
      </Form>
    );
  };

  if (isLoggedIn === null) {
    return (
      <Container className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  } else if (
    isLoggedIn === "consultant" ||
    isLoggedIn === "admin" ||
    isLoggedIn === "consumer"
  ) {
    return (
      <>
        {renderFilters()}
        {renderTableOfOldConsults()}
      </>
    );
  } else if (isLoggedIn === "call_center") {
    return <Navigate to={`${pagePrefix}/dashboard`} replace />;
  } else {
    return <Navigate to={`${pagePrefix}/login`} replace />;
  }
};

export default OldConsults;
