import React, { useState } from "react";
import Select from "react-select";

const ExistingOrgsDropdown = (props) => {
  const registration = props.registration || false;
  const passedPatient = props.passedPatient;
  const organizations = props.organizations;
  let organizationsOptions = [];
  if (organizations) {
    organizationsOptions = organizations.map((org) => ({
      value: org.id,
      label: `${org.name} - ${org.city}`,
    }));
  }
  if (registration && !passedPatient) {
    organizationsOptions.unshift({ value: "0", label: "New Organization" });
  }
  const [organizationId, setOrganizationId] = props.organizationId;
  const [selectedOrg, setSelectedOrg] = useState(null);

  const handleSelection = (selectedOrg) => {
    setSelectedOrg(selectedOrg);
    setOrganizationId(selectedOrg.value);
  };

  return (
    <div className="form-group mb-3">
      <Select
        defaultValue={selectedOrg}
        onChange={handleSelection}
        options={organizationsOptions}
        placeholder="Select your organization"
      />
    </div>
  );
};

export default ExistingOrgsDropdown;
