import React, { useEffect, useState } from "react";
import axios from "axios";
import { LinkContainer } from "react-router-bootstrap";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";

import { useAuth } from "../../layout/AuthProvider";
import { pagePrefix } from "../../../utils/pagePrefix";

const OrgsAdmin = () => {
  const { isLoggedIn } = useAuth();
  const [orgs, setOrgs] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [showActive, setShowActive] = useState(false);
  const [nameFilter, setNameFilter] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const [orgName, setOrgName] = useState("");
  const [orgType, setOrgType] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (!showActive) {
      axios
        .get("/api/v1/organizations/admin")
        .then((res) => {
          setOrgs(res.data);
        })
        .catch((err) => {
          console.error(err);
          alert("Failed to get organizations");
        });
    } else {
      axios
        .get("/api/v1/organizations/active")
        .then((res) => {
          setOrgs(res.data);
        })
        .catch((err) => {
          console.error(err);
          alert("Failed to get organizations");
        });
    }
  }, [isLoggedIn, refresh, showActive]);

  const filterOrgs = (os) => {
    if (!nameFilter) {
      return os;
    }
    return os.filter((org) => {
      return org.name.toLowerCase().includes(nameFilter.toLowerCase());
    });
  };

  const constructOrgAddress = (org) => {
    if (!org.addressLine2) {
      return (
        org.addressLine1 + ", " + org.city + ", " + org.state + " " + org.zip
      );
    }
    return (
      org.addressLine1 +
      ", " +
      org.addressLine2 +
      ", " +
      org.city +
      ", " +
      org.state +
      " " +
      org.zip
    );
  };

  const handleShow = (org) => {
    setOrgId(org.id);
    setOrgName(org.name);
    setOrgType(org.type);
    setAddressLine1(org.addressLine1);
    setAddressLine2(org.addressLine2);
    setCity(org.city);
    setState(org.state);
    setZip(org.zip);
    setPhone(org.phoneNumber);
    setShowModal(true);
  };

  const handleClose = () => {
    setOrgId(null);
    setOrgName("");
    setOrgType("");
    setAddressLine1("");
    setAddressLine2("");
    setCity("");
    setState("");
    setZip("");
    setPhone("");
    setShowModal(false);
  };

  const handleSave = () => {
    if (!orgName || !addressLine1 || !city || !state || !zip || !phone) {
      alert("Please fill out all fields");
      return;
    }
    axios
      .put(`/api/v1/organizations/${orgId}`, {
        name: orgName,
        type: orgType,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        state: state,
        zip: zip,
        phone: phone,
      })
      .then(() => {
        setRefresh(!refresh);
        handleClose();
      })
      .catch((err) => {
        console.error(err);
        alert("Failed to save organization");
      });
  };

  const renderModal = () => {
    return (
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={orgName}
                onChange={(e) => setOrgName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Type</Form.Label>
              <Form.Select
                value={orgType}
                onChange={(e) => setOrgType(e.target.value)}
              >
                <option value="Primary Care Practice">
                  Primary Care Practice
                </option>
                <option value="School">School</option>
                <option value="Emergency Department">
                  Emergency Department
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Address Line 1</Form.Label>
              <Form.Control
                type="text"
                value={addressLine1}
                onChange={(e) => setAddressLine1(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                type="text"
                value={addressLine2}
                onChange={(e) => setAddressLine2(e.target.value)}
              />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Zip</Form.Label>
                  <Form.Control
                    type="text"
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleSave()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderOrgsTable = () => {
    if (orgs.length === 0) {
      return (
        <tr>
          <td colSpan="5">No organizations found</td>
        </tr>
      );
    }
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Address</th>
            <th>Phone</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {filterOrgs(orgs).map((org) => (
            <tr key={org.id}>
              <td>{org.name}</td>
              <td>{constructOrgAddress(org)}</td>
              <td>{org.phoneNumber}</td>
              <td>
                <Button variant="primary" onClick={() => handleShow(org)}>
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const renderFilters = () => {
    return (
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Filter by organization name</Form.Label>
          <Form.Control
            type="text"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          />
        </Form.Group>
      </Form>
    );
  };

  if (isLoggedIn === null) {
    return <div>Loading...</div>;
  } else if (isLoggedIn === "admin") {
    return (
      <Container>
        <h1>Organizations</h1>
        <LinkContainer to={`${pagePrefix}/admin/register/organization`}>
          <Button variant="primary" size="sm">
            Register New Organization
          </Button>
        </LinkContainer>
        {renderFilters()}
        <Form>
          <Form.Check
            type="switch"
            id="active-orgs-switch"
            label="Show only active organizations"
            checked={showActive}
            onChange={() => setShowActive(!showActive)}
          />
        </Form>
        {renderOrgsTable()}
        {renderModal()}
      </Container>
    );
  } else {
    return <p>Unauthorized</p>;
  }
};

export default OrgsAdmin;
