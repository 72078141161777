import React from "react";
import { Col, Row } from "react-bootstrap";

import ConsultsTable from "../Consult/ConsultsTable";
import ReservedConsultsTable from "../Consult/ReservedConsultsTable";

const Dashboard = () => {
  return (
    <Row>
      <Col>
        <ConsultsTable />
      </Col>
      <Col>
        <ReservedConsultsTable />
      </Col>
    </Row>
  );
};

export default Dashboard;
