import React, { useState } from "react";
import axios from "axios";
import { Col, Container, Form, Row } from "react-bootstrap";

const InitiatePasswordReset = () => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      return;
    }
    try {
      const res = await axios.post("/api/v1/users/password/reset/initiate", {
        email,
      });
      console.log(res);
      if (res.status === 200) {
        alert(res.data.message);
      }
    } catch {
      alert(
        "Unable to fulfill password reset request. Please check the email you provided and try again later."
      );
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <h1>Initiate Password Reset</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Control
                type="email"
                placeholder="Email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </Form.Group>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default InitiatePasswordReset;
