import React from "react";

const ConsultServicesProvidedAdmin = (props) => {
  const diagnosticClarification = props.diagnosticClarification;
  const medicationAdjustment = props.medicationAdjustment;
  const treatmentPlanning = props.treatmentPlanning;
  const crisisInterventionRecommendations =
    props.crisisInterventionRecommendations;
  const otherServiceProvided = props.otherServiceProvided;

  return (
    <div>
      <h5>Services Provided:</h5>

      <ul>
        {diagnosticClarification ? <li>Diagnostic Clarification</li> : null}
        {medicationAdjustment ? <li>Medication Adjustment</li> : null}
        {treatmentPlanning && <li>Treatment Planning</li>}
        {crisisInterventionRecommendations && (
          <li>Crisis Intervention Recommendations</li>
        )}
        {otherServiceProvided ? <li>Other: {otherServiceProvided}</li> : null}
      </ul>
    </div>
  );
};

export default ConsultServicesProvidedAdmin;
