import React from "react";
import { Form } from "react-bootstrap";

const UserCredentials = (props) => {
  const [credential, setCredential] = props.credential;
  const required = props.required ?? true;

  return (
    <Form.Group className="mb-3">
      <Form.Select
        name="credential"
        value={credential}
        onChange={(e) => setCredential(e.target.value)}
        required={required}
      >
        <option value="">Select Credential</option>
        <option value="MD/DO">MD/DO</option>
        <option value="APRN/NP (general or specialist)">
          APRN/NP (general or specialist)
        </option>
        <option value="PA (general or specialist)">
          PA (general or specialist)
        </option>
        <option value="PhD">PhD</option>
        <option value="LCSW/CSW">LCSW/CSW</option>
        <option value="Certified Nurse Midwife">Certified Nurse Midwife</option>
        <option value="Registered Nurse">Registered Nurse</option>
        <option value="Other">Other</option>
      </Form.Select>
    </Form.Group>
  );
};

export default UserCredentials;
