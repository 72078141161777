import React from "react";
import axios from "axios";
import { Button } from "react-bootstrap";

import { useAuth } from "../layout/AuthProvider";

const ConsultQuestionAnswered = (props) => {
  const { isLoggedIn } = useAuth();
  const consult = props.consult;
  const setRefresh = props.setRefresh;

  const handleQuestionComplete = async () => {
    try {
      const response = await axios.post(
        `/api/v1/consults/${consult.id}/answered`
      );
      if (response.status === 200) {
        console.log("Consult answered");
        setRefresh((prev) => !prev);
      }
    } catch (error) {
      console.error("Error marking consult as answered:", error);
    }
  };

  const renderButton = () => {
    if (consult.consumerQuestionAnswered) {
      return (
        <Button disabled={true} variant="success">
          Question Answered
        </Button>
      );
    } else if (isLoggedIn === "consumer") {
      return (
        <>
          <p>
            Click to Indicate Your Question Has Been Answered: &nbsp;
            <Button variant="primary" onClick={handleQuestionComplete}>
              Done
            </Button>
          </p>
        </>
      );
    }
  };

  return renderButton();
};

export default ConsultQuestionAnswered;
