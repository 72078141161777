import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, Form, Row } from "react-bootstrap";

const ConsultAssigner = (props) => {
  const consult = props.consult;
  const setRefresh = props.setRefresh;
  const [consultants, setConsultants] = useState([]);
  const [assignedConsultant, setAssignedConsultant] = useState(
    consult.reservedForId
  );

  useEffect(() => {
    axios
      .get("/api/v1/users/consultants", { withCredentials: true })
      .then((res) => {
        if (res.status === 200) {
          setConsultants(res.data);
        }
      })
      .catch((error) => {
        console.error("Failed to get consultants", error);
      });
  }, []);

  const saveAssignment = async (id) => {
    try {
      const response = await axios.post(
        `/api/v1/consults/${consult.id}/assign`,
        {
          consultantId: id,
        }
      );
      if (response.status === 200) {
        setAssignedConsultant(id);
        setRefresh((prev) => !prev);
        alert("Consultant assigned successfully");
      } else {
        console.error("Failed to assign consultant");
      }
    } catch (error) {
      console.error("Error assigning consultant:", error);
      alert("Failed to assign consultant");
    }
  };

  const renderSubtype = (consultant) => {
    if (consultant.subtype === "psychiatrist") {
      return "(Psy)";
    } else if (consultant.subtype === "care coordinator") {
      return "(Care)";
    } else {
      return null;
    }
  };

  const renderAssignmentDropdown = () => {
    return (
      <Form.Select
        name="consultantId"
        value={assignedConsultant}
        onChange={(e) => saveAssignment(e.target.value)}
      >
        <option value="Unassigned">Unassigned</option>
        {consultants.map((consultant) => (
          <option key={consultant.id} value={consultant.id}>
            {renderSubtype(consultant)} {consultant.firstName}{" "}
            {consultant.lastName}
          </option>
        ))}
      </Form.Select>
    );
  };

  if (consult.status !== "open") {
    return null;
  }
  return (
    <Form className="mb-2">
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Assigned to:
        </Form.Label>
        <Col sm={4}>{renderAssignmentDropdown()}</Col>
      </Form.Group>
    </Form>
  );
};

export default ConsultAssigner;
