import React from "react";
import { Form } from "react-bootstrap";

import { useAuth } from "../layout/AuthProvider";
import { Asterisk } from "../utils/Asterisk";

const ConsultTimeSpent = (props) => {
  const { isLoggedIn } = useAuth();
  const [timeSpent, setTimeSpent] = props.timeSpent || [null, () => {}];

  const isDisabled = () => {
    if (isLoggedIn !== "consultant") return true;
    return false;
  };

  return (
    <Form.Group controlId="timeSpent" className="mb-3">
      <Form.Label>
        Time Spent on Consult: <Asterisk />
      </Form.Label>
      <Form.Control
        type="text"
        placeholder="Please provide an estimate of the time spent on the consult"
        name="timeSpent"
        value={timeSpent}
        onChange={(e) => setTimeSpent(e.target.value)}
        disabled={isDisabled()}
      />
    </Form.Group>
  );
};

export default ConsultTimeSpent;
