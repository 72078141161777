import React, { useState } from "react";
import { Form } from "react-bootstrap";

const PasswordForm = (props) => {
  const [password, setPassword] = props.password;
  const [password2, setPassword2] = props.password2;
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    // Regular expression to enforce the constraints
    const passwordPattern =
      /^(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?])(?=.*[A-Z])(?=.*[a-z]).{8,25}$/;
    setIsPasswordValid(passwordPattern.test(value));
  };

  return (
    <div>
      <Form.Group className="mb-3">
        <Form.Control
          type="password"
          placeholder="Password"
          name="password"
          value={password}
          onChange={handlePasswordChange}
          required
          isInvalid={!isPasswordValid}
        />
        <Form.Control.Feedback type="invalid">
          Password must be between 8 and 25 characters, and include at least one
          number, one symbol ($, &, @, %, _, *), one uppercase letter, and one
          lowercase letter.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Control
          type="password"
          placeholder="Confirm Password"
          name="password2"
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
          required
        />
      </Form.Group>
    </div>
  );
};

export default PasswordForm;
