import React from "react";

import { useAuth } from "../layout/AuthProvider";
import ConsultAssigner from "./ConsultAssigner";
import ConsultStatusManager from "./ConsultStatusManager";
import ConsultCareCoordinationRequest from "./ConsultCareCoordinationRequest";
import RelatedConsults from "./RelatedConsults";

const ConsultAdministration = (props) => {
  const { isLoggedIn } = useAuth();
  const consult = props.consult;
  const setRefresh = props.setRefresh;
  const relatedConsults = props.relatedConsults;

  if (isLoggedIn !== "consultant" && isLoggedIn !== "admin") {
    return null;
  }
  return (
    <div className="mb-3">
      <ConsultAssigner consult={consult} setRefresh={setRefresh} />
      <ConsultStatusManager consult={consult} setRefresh={setRefresh} />
      <ConsultCareCoordinationRequest
        consult={consult}
        setRefresh={setRefresh}
      />
      <RelatedConsults relatedConsults={relatedConsults} />
    </div>
  );
};

export default ConsultAdministration;
