import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { formatDistance } from "date-fns";

import { toReadableDate } from "../../utils/toReadableDate";

const EventsTable = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    axios
      .get("/api/v1/events")
      .then((res) => {
        console.log("Events fetched", res.data);
        setEvents(res.data);
      })
      .catch((error) => {
        console.error("Failed to fetch events", error);
      });
  }, []);

  const duration = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return formatDistance(endDate, startDate).replace("about", "");
  };

  const determineIfLocationIsLink = (location) => {
    if (location.startsWith("http")) {
      return (
        <Button href={location} target="_blank" rel="noreferrer">
          Link
        </Button>
      );
    } else {
      return location;
    }
  };

  return (
    <div>
      <h4>Events</h4>
      {events.length === 0 ? (
        <p>No events yet</p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Title</th>
              <th>Start</th>
              <th>Description</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event) => (
              <tr key={event.id}>
                <td>{event.title}</td>
                <td>
                  {toReadableDate(event.start)} <br /> Duration:{" "}
                  {duration(event.start, event.end)}
                </td>
                <td>{event.description}</td>
                <td>{determineIfLocationIsLink(event.location)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default EventsTable;
