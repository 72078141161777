import React, { useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

import { pagePrefix } from "../../../utils/pagePrefix";
import PasswordForm from "../../utils/PasswordForm";

const PasswordReset = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newPassword || !newPassword2 || newPassword !== newPassword2) {
      return;
    }
    try {
      const res = await axios.put("/api/v1/users/password/reset", {
        token,
        newPassword,
      });
      if (res.status === 200) {
        alert(res.data.message);
        navigate(`${pagePrefix}/login`);
      }
    } catch {
      alert(
        "Unable to fulfill password reset request. Please try again later."
      );
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <h1>Initiate Password Reset</h1>
          <Form onSubmit={handleSubmit}>
            <PasswordForm
              password={[newPassword, setNewPassword]}
              password2={[newPassword2, setNewPassword2]}
            />
            <Button type="submit">Submit</Button>
          </Form>
          <p className="my-1">
            Need a new password reset link?{" "}
            <Link to={`${pagePrefix}/password/reset/initiate`}>Click Here</Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordReset;
