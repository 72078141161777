import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";

import UserCredentials from "../utils/UserCredentials";
import UserRole from "../utils/UserRole";
import { Asterisk } from "../utils/Asterisk";

const CallerForm = (props) => {
  const organizationId = props.organizationId;
  const [callerId, setCallerId] = props.callerId;
  const [callerFirstName, setCallerFirstName] = props.callerFirstName;
  const [callerLastName, setCallerLastName] = props.callerLastName;
  const [callerPhone, setCallerPhone] = props.callerPhone;
  const [callerEmail, setCallerEmail] = props.callerEmail;
  const [callerCredentials, setCallerCredentials] = props.callerCredentials;
  const [callerRole, setCallerRole] = props.callerRole;
  const [callers, setCallers] = useState([]);

  useEffect(() => {
    const fetchCallers = async () => {
      if (organizationId === "" || organizationId === "0") {
        setCallers([]);
        setCallerId("");
        return;
      }
      try {
        const response = await axios.get(
          `/api/v1/users/organizations/${organizationId}`
        );
        if (
          props.callerId[0] &&
          props.callerId[0] !== "" &&
          props.callerId[0] !== "0" &&
          typeof props.callerId[0] !== "undefined" &&
          props.callerId[0] !== "undefined" &&
          props.callerId[0] !== undefined &&
          props.callerId[0] !== null
        ) {
          setCallers(
            response.data.filter((caller) => caller.id == props.callerId[0])
          );
        } else {
          setCallers(response.data);
          setCallerId("");
        }
      } catch (error) {
        console.error("Failed to fetch callers:", error);
      }
    };
    fetchCallers();
  }, [organizationId, setCallerId]);

  const renderCallerDropdown = () => {
    return (
      <Form.Group controlId="callerId" className="mb-3">
        <Form.Select
          value={callerId}
          onChange={(e) => setCallerId(e.target.value)}
        >
          <option value="">Select Caller</option>
          <option value="0">Add New User</option>
          {callers.map((caller) => (
            <option key={caller.id} value={caller.id}>
              {caller.firstName} {caller.lastName}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    );
  };

  const renderNewCallerForm = () => {
    if (callerId !== "0") return null;
    return (
      <>
        <Form.Group controlId="callerFirstName" className="mb-3">
          <Form.Control
            type="text"
            placeholder="First Name"
            name="callerFirstName"
            value={callerFirstName}
            onChange={(e) => setCallerFirstName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="callerLastName" className="mb-3">
          <Form.Control
            type="text"
            placeholder="Last Name"
            name="callerLastName"
            value={callerLastName}
            onChange={(e) => setCallerLastName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="callerPhone" className="mb-3">
          <Form.Control
            type="text"
            placeholder="Phone"
            name="callerPhone"
            value={callerPhone}
            onChange={(e) => setCallerPhone(e.target.value)}
            required
            minLength={10}
            maxLength={22}
          />
        </Form.Group>
        <Form.Group controlId="callerEmail" className="mb-3">
          <Form.Control
            type="email"
            placeholder="Email"
            name="callerEmail"
            value={callerEmail}
            onChange={(e) => setCallerEmail(e.target.value)}
            required
          />
        </Form.Group>
        <UserRole roleType={[callerRole, setCallerRole]} />
        <UserCredentials
          credential={[callerCredentials, setCallerCredentials]}
        />
      </>
    );
  };

  return (
    <>
      <h4>
        Caller Info: <Asterisk />
      </h4>
      {renderCallerDropdown()}
      {renderNewCallerForm()}
    </>
  );
};

export default CallerForm;
