import React, { useEffect, useState } from "react";
import axios from "axios";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { toReadableDate } from "../../utils/toReadableDate";
import { useAuth } from "../layout/AuthProvider";
import { pagePrefix } from "../../utils/pagePrefix";
import { isLastCommentFromOtherParty } from "../../utils/isLastCommentFromOtherParty";

const ConsultsTable = () => {
  const { isLoggedIn } = useAuth();
  const [consults, setConsults] = useState([]);

  useEffect(() => {
    const sortConsultsThatAreNotReservedFirst = (consults) => {
      if (isLoggedIn === "consumer") {
        return consults;
      }
      return consults.sort((a, b) => {
        if (a.reservedForId === null && b.reservedForId !== null) {
          return -1;
        }
        if (a.reservedForId !== null && b.reservedForId === null) {
          return 1;
        }
        return 0;
      });
    };
    axios
      .get("/api/v1/consults", { withCredentials: true })
      .then((res) => {
        if (res.status === 200) {
          setConsults(sortConsultsThatAreNotReservedFirst(res.data));
        }
      })
      .catch((error) => {
        console.error("Failed to get consults", error);
      });
  }, [isLoggedIn]);

  const renderConsults = () => {
    if (consults.length === 0) {
      return <tr></tr>;
    }
    return consults.map((consult) => {
      return (
        <tr key={consult.id}>
          <td>
            {isLastCommentFromOtherParty(consult, isLoggedIn) ? (
              <>
                <FontAwesomeIcon
                  icon="fa-solid fa-circle"
                  style={{ color: "#ff0000" }}
                />{" "}
                <br />
              </>
            ) : null}
            {consult.id}
          </td>
          <td>
            {consult.callup_patient.firstName} {consult.callup_patient.lastName}
          </td>
          <td>{toReadableDate(consult.createdAt)}</td>
          {isLoggedIn === "consumer" ? null : (
            <td>
              {consult.createdByRole.callup_user.lastName} at{" "}
              {consult.callup_organization.name}
            </td>
          )}
          {isLoggedIn === "consumer" ? null : (
            <td>
              {consult.neededCareCoordination ? (
                <>
                  <FontAwesomeIcon icon="fa-solid fa-check" size="2xl" /> <br />
                  {toReadableDate(consult.careCoordinationRequestedAt)}
                </>
              ) : null}
            </td>
          )}
          {isLoggedIn === "admin" || isLoggedIn === "consultant" ? (
            <td>
              {consult.reservedFor
                ? consult.reservedFor.firstName +
                  " " +
                  consult.reservedFor.lastName
                : null}
            </td>
          ) : null}
          {isLoggedIn === "admin" || isLoggedIn === "consultant" ? (
            <td>{consult.callAttempts === 0 ? null : consult.callAttempts}</td>
          ) : null}
          {isLoggedIn === "consumer" ? null : (
            <td>
              {consult.communicationMethod === "online" ? "Online" : "Phone"}
            </td>
          )}
          {isLoggedIn === "call_center" ? null : (
            <td>
              <LinkContainer to={`${pagePrefix}/consult/${consult.id}`}>
                <Button variant="primary" size="sm">
                  View Consult
                </Button>
              </LinkContainer>
            </td>
          )}
        </tr>
      );
    });
  };
  return (
    <>
      <h4>Open Consults</h4>
      <Table striped bordered hover responsive="sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>Patient Name</th>
            <th>Requested At</th>
            {isLoggedIn === "consumer" ? null : <th>Requester</th>}
            {isLoggedIn === "consumer" ? null : (
              <th>Needs Care Coordination</th>
            )}
            {isLoggedIn === "admin" || isLoggedIn === "consultant" ? (
              <th>Assigned to</th>
            ) : null}
            {isLoggedIn === "admin" || isLoggedIn === "consultant" ? (
              <th>Call Attempts</th>
            ) : null}
            {isLoggedIn === "consumer" ? null : (
              <th>Preferred Communication Method</th>
            )}
            {isLoggedIn === "call_center" ? null : <th>Link</th>}
          </tr>
        </thead>
        <tbody>{renderConsults()}</tbody>
      </Table>
    </>
  );
};

export default ConsultsTable;
