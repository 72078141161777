import React, { useState } from "react";
import axios from "axios";
import { Button, Col, Form, Modal } from "react-bootstrap";

import { useAuth } from "../layout/AuthProvider";

const ConsultCareCoordinationRequest = (props) => {
  const { isLoggedIn } = useAuth();
  const consult = props.consult;
  const setRefresh = props.setRefresh;
  const [reason, setReason] = useState("");
  const [showModal, setShowModal] = useState(false);

  const submitCareCoordinationRequest = async () => {
    if (reason === "") {
      alert("Please provide a reason for the care coordination request");
      return;
    }
    try {
      if (
        consult.status !== "open" ||
        consult.neededCareCoordination === true
      ) {
        alert("Care coordination is not needed or has already been requested");
        return;
      }
      const response = await axios.post(`/api/v1/consults/${consult.id}/care`, {
        reason,
      });
      if (response.status === 200) {
        setShowModal(false);
        setRefresh((prev) => !prev);
      } else {
        console.error("Failed to request care coordination");
      }
    } catch (error) {
      console.error("Error requesting care coordination:", error);
    }
  };

  const deleteCareCoordinationRequest = async () => {
    try {
      const response = await axios.delete(
        `/api/v1/consults/${consult.id}/care`,
        { withCredentials: true }
      );
      if (response.status === 200) {
        setRefresh((prev) => !prev);
      } else {
        console.error("Failed to delete care coordination request");
      }
    } catch (error) {
      console.error("Error deleting care coordination request:", error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setReason("");
  };

  const renderCareCoordinationModal = () => {
    return (
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Request Care Coordination</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <li>Please fill out the reason for requesting care coordination</li>
            <li>Leave the consult status as "Open"</li>
            <li>You may assign this consult to a care coordinator</li>
          </ul>
          <Form>
            <Form.Group controlId="reason">
              <Form.Label>Reason for Care Coordination</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={submitCareCoordinationRequest}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderCareCoordinationButton = () => {
    if (consult.neededCareCoordination === true) {
      return (
        <div>
          <h6>
            <b>
              Reason for care coordination request:{" "}
              {consult.reasonForCareCoordination}
            </b>
          </h6>
          <Button variant="danger" onClick={deleteCareCoordinationRequest}>
            Delete Care Coordination Request
          </Button>
        </div>
      );
    } else {
      return (
        <Button
          type="button"
          className="btn btn-primary"
          onClick={() => setShowModal(true)}
          disabled={consult.status !== "open" || isLoggedIn !== "consultant"}
        >
          Request Care Coordination
        </Button>
      );
    }
  };

  return (
    <Col sm={{ span: 10, offset: 2 }} className="mb-4">
      {renderCareCoordinationButton()}
      {renderCareCoordinationModal()}
    </Col>
  );
};

export default ConsultCareCoordinationRequest;
