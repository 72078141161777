import React from "react";
import { Col, Row } from "react-bootstrap";

import ConsultsTable from "../Consult/ConsultsTable";
import OldConsultsTable from "../Consult/OldConsultsTable";
import EventsTable from "../Events/EventsTable";

const ConsumerDashboard = () => {
  return (
    <Row>
      <Col md={8}>
        <ConsultsTable />
        <OldConsultsTable />
      </Col>
      <Col md={4}>
        <EventsTable />
      </Col>
    </Row>
  );
};

export default ConsumerDashboard;
