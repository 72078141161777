import React from "react";
import { Form } from "react-bootstrap";

import { toBirthDay } from "../../utils/toBirthDay";

const ExistingPatientsDropdown = (props) => {
  const sortPatientsByLastName = (propPatients) => {
    return propPatients.sort((a, b) =>
      a.lastName > b.lastName ? 1 : b.lastName > a.lastName ? -1 : 0
    );
  };

  const registration = props.registration || false;
  const patients = sortPatientsByLastName(props.patients);
  const passedPatient = props.passedPatient;
  const [patientId, setPatientId] = props.patientId;

  const renderPatientOption = (patient) => {
    return (
      <option key={patient.id} value={patient.id}>
        {patient.lastName}, {patient.firstName} - {toBirthDay(patient.DOB)}
      </option>
    );
  };

  const renderPatients = () => {
    if (patients.length > 0) {
      if (passedPatient && patientId) {
        return patients.map((patient) => {
          if (patient.id == patientId) {
            return renderPatientOption(patient);
          }
          return null;
        });
      }
      return patients.map((patient) => renderPatientOption(patient));
    }
  };

  return (
    <Form.Group className="mb-3">
      <Form.Select
        name="patientId"
        value={patientId}
        onChange={(e) => setPatientId(e.target.value)}
      >
        {passedPatient ? null : <option value="">Select Patient</option>}
        {passedPatient ? null : registration ? (
          <option value="0">Add New Patient</option>
        ) : null}
        {renderPatients()}
      </Form.Select>
    </Form.Group>
  );
};

export default ExistingPatientsDropdown;
