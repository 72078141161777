import React from "react";
import { Row, Col } from "react-bootstrap";
import ConsultsTable from "../Consult/ConsultsTable";

const CallCenterDashboard = () => {
  return (
    <Row>
      <Col xs={2}></Col>
      <Col xs={8}>
        <ConsultsTable />
      </Col>
      <Col xs={2}></Col>
    </Row>
  );
};

export default CallCenterDashboard;
