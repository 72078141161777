// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { isLoggedIn } from "../../utils/isLoggedIn";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [authStatus, setAuthStatus] = useState(null);
  const [first, setFirst] = useState(null);
  const [loginState, setLoginState] = useState(false);
  const [userStatus, setUserStatus] = useState(null);

  useEffect(() => {
    const checkLoginStatus = async () => {
      const isAuthenticated = await isLoggedIn();
      if (isAuthenticated) {
        setAuthStatus(isAuthenticated.userType);
        setFirst(isAuthenticated.firstName);
        setUserStatus(isAuthenticated.status);
      } else {
        setAuthStatus(isAuthenticated);
        setFirst(null);
        setUserStatus(null);
      }
    };
    checkLoginStatus();
  }, [loginState]);

  return (
    <AuthContext.Provider
      value={{ isLoggedIn: authStatus, first, setLoginState, userStatus }}
    >
      {children}
    </AuthContext.Provider>
  );
};
