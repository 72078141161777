import React, { useState } from "react";
import axios from "axios";
import { Button, Form } from "react-bootstrap";

const CallAttempts = (props) => {
  const consult = props.consult;
  const setRefresh = props.setRefresh;
  const setRefreshable = props.setRefreshable;
  const [callAttempts, setCallAttempts] = useState(
    props.consult.callAttempts || 0
  );
  const [callComments, setCallComments] = useState(
    props.consult.callComments || ""
  );

  const handleCallAttemptsChange = (e) => {
    const value = e.target.value;
    setCallAttempts(value);
    setRefreshable(false);
  };

  const handleCallCommentsChange = (e) => {
    const value = e.target.value;
    setCallComments(value);
    setRefreshable(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `/api/v1/consults/${consult.id}/calls`,
        {
          callAttempts: callAttempts,
          callComments: callComments,
        }
      );
      if (response.status === 200) {
        setRefresh((prev) => !prev);
        alert("Call attempts updated successfully");
      }
    } catch (error) {
      console.error("Failed to update call attempts", error);
      alert("Failed to update call attempts");
    }
    setRefreshable(true);
  };

  return (
    <Form className="mb-3">
      <Form.Group className="mb-2" controlId="callAttempts">
        <Form.Label>Call Attempts:</Form.Label>
        <Form.Control
          className="mb-2"
          type="number"
          value={callAttempts}
          onChange={(e) => handleCallAttemptsChange(e)}
          min={0}
        />
        <Form.Label>Call Notes:</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Notes about the call"
          name="callComments"
          value={callComments}
          onChange={(e) => handleCallCommentsChange(e)}
          rows={3}
        />
      </Form.Group>
      <Button variant="primary" onClick={onSubmit}>
        Save
      </Button>
    </Form>
  );
};

export default CallAttempts;
