import axios from "axios";

export const isLoggedIn = async () => {
  try {
    const res = await axios.get("/api/v1/users", { withCredentials: true });
    if (res.status === 200) {
      return res.data;
    }
    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
};
