import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";

import { useAuth } from "../../layout/AuthProvider";
import { pagePrefix } from "../../../utils/pagePrefix";
import PasswordForm from "../../utils/PasswordForm";

const Profile = () => {
  const { isLoggedIn, setLoginState } = useAuth();
  const [user, setUser] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("/api/v1/users/profile")
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleUpdatePassword = async () => {
    if (!password || !newPassword || !confirmPassword) {
      alert("All fields are required");
      return;
    }
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    try {
      const res = await axios.put("/api/v1/users/password/update", {
        password,
        newPassword,
      });
      if (res.status === 200) {
        setLoginState(false);
        alert("Password updated successfully");
        navigate(`${pagePrefix}/login`);
      }
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 401) {
        alert(error.response.data.errors[0].msg);
      }
      console.error(error);
    }
  };

  const renderModal = () => {
    return (
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleUpdatePassword}>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter current password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            <PasswordForm
              password={[newPassword, setNewPassword]}
              password2={[confirmPassword, setConfirmPassword]}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleUpdatePassword()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  if (isLoggedIn === false) {
    return <Navigate to={`${pagePrefix}/login`} replace />;
  }
  return (
    <div>
      <h1>Profile</h1>
      <h4>
        {user.firstName} {user.lastName}
      </h4>
      <p>{user.email}</p>
      <p>{user.phoneNumber}</p>
      <Button variant="primary" onClick={() => setShowModal(true)}>
        Update Password
      </Button>
      {renderModal()}
    </div>
  );
};

export default Profile;
