import React from "react";
import { Navigate } from "react-router-dom";
import { Container, Spinner } from "react-bootstrap";
import ConsumerDashboard from "../ConsumerDashboard";
import ConsultantDashboard from "../ConsultantDashboard";
import { useAuth } from "../../layout/AuthProvider";
import AdminDashboard from "../AdminDashboard";
import CallCenterDashboard from "../CallCenterDashboard";
import { pagePrefix } from "../../../utils/pagePrefix";

const Dashboard = () => {
  const { isLoggedIn } = useAuth();

  const renderDashboardForUserType = () => {
    if (isLoggedIn === "consumer") {
      return <ConsumerDashboard />;
    } else if (isLoggedIn === "consultant") {
      return <ConsultantDashboard />;
    } else if (isLoggedIn === "admin") {
      return <AdminDashboard />
    } else if (isLoggedIn === "call_center") {
      return <CallCenterDashboard />;
    }
  };

  if (isLoggedIn === false) {
    return <Navigate to={`${pagePrefix}/login`} replace />;
  }

  return (
    <Container className="text-center">
      {isLoggedIn ? (
        renderDashboardForUserType()
      ) : (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </Container>
  );
};

export default Dashboard;
