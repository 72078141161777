import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Navigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";

import OrgRegistration from "../Orgs/OrgRegistration";
import PatientRegistration from "../Patients/PatientRegistration";
import NewConsultForm from "./NewConsultForm";
import CallerForm from "./CallerForm";
import { useAuth } from "../layout/AuthProvider";
import { pagePrefix } from "../../utils/pagePrefix";

const NewConsultEmployee = (props) => {
  const { isLoggedIn } = useAuth();
  const [organizations, setOrganizations] = useState([]);
  const [organizationId, setOrganizationId] = useState(
    String(props.organizationId) || ""
  );
  const [organizationName, setOrganizationName] = useState("");
  const [organizationType, setOrganizationType] = useState("");
  const [organizationAddressLine1, setOrganizationAddressLine1] = useState("");
  const [organizationAddressLine2, setOrganizationAddressLine2] = useState("");
  const [organizationCity, setOrganizationCity] = useState("");
  const [organizationCounty, setOrganizationCounty] = useState("");
  const [organizationState, setOrganizationState] = useState("UT");
  const [organizationZip, setOrganizationZip] = useState("");
  const [organizationPhone, setOrganizationPhone] = useState("");
  const [callerId, setCallerId] = useState(String(props.callerId) || "");
  const [callerFirstName, setCallerFirstName] = useState("");
  const [callerLastName, setCallerLastName] = useState("");
  const [callerPhone, setCallerPhone] = useState("");
  const [callerEmail, setCallerEmail] = useState("");
  const [callerCredentials, setCallerCredentials] = useState("");
  const [callerRole, setCallerRole] = useState("");
  const [patients, setPatients] = useState([]);
  const [patientId, setPatientId] = useState(String(props.patientId) || "");
  const passedPatient = props.patientId ? true : false;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [DOB, setDOB] = useState(null);
  const [age, setAge] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [race, setRace] = useState(null);
  const [gender, setGender] = useState(null);
  const [ethnicity, setEthnicity] = useState(null);
  const [MRN, setMRN] = useState(null);
  const [reasonForConsult, setReasonForConsult] = useState("");
  const [anxietyDisorders, setAnxietyDisorders] = useState(false);
  const [
    depressiveDisordersExcludingPostpartumDepression,
    setDepressiveDisordersExcludingPostpartumDepression,
  ] = useState(false);
  const [postpartumDepression, setPostpartumDepression] = useState(false);
  const [bipolarDisorders, setBipolarDisorders] = useState(false);
  const [adhd, setAdhd] = useState(false);
  const [autismSpectrumDisorder, setAutismSpectrumDisorder] = useState(false);
  const [
    intellectualDevelopmentalDisabilities,
    setIntellectualDevelopmentalDisabilities,
  ] = useState(false);
  const [
    disruptiveImpulseControlConductDisorders,
    setDisruptiveImpulseControlConductDisorders,
  ] = useState(false);
  const [feedingEatingDisorders, setFeedingEatingDisorders] = useState(false);
  const [
    obsessiveCompulsiveAndRelatedDisorders,
    setObsessiveCompulsiveAndRelatedDisorders,
  ] = useState(false);
  const [
    traumaAndStressorRelatedDisorders,
    setTraumaAndStressorRelatedDisorders,
  ] = useState(false);
  const [
    schizophreniaAndOtherPsychoticDisorders,
    setSchizophreniaAndOtherPsychoticDisorders,
  ] = useState(false);
  const [substanceRelatedAlcohol, setSubstanceRelatedAlcohol] = useState(false);
  const [substanceRelatedMarijuana, setSubstanceRelatedMarijuana] =
    useState(false);
  const [substanceRelatedNicotine, setSubstanceRelatedNicotine] =
    useState(false);
  const [substanceRelatedOpioid, setSubstanceRelatedOpioid] = useState(false);
  const [substanceRelatedOthers, setSubstanceRelatedOthers] = useState(false);
  const [sleepDisorders, setSleepDisorders] = useState(false);
  const [suicidalitySelfHarm, setSuicidalitySelfHarm] = useState(false);
  const [dementiaDelirium, setDementiaDelirium] = useState(false);
  const [otherCondition, setOtherCondition] = useState();
  const [communicationMethod, setCommunicationMethod] = useState("");
  const [callbackTime, setCallbackTime] = useState("");
  const [fosterCare, setFosterCare] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("/api/v1/users/organizations", { withCredentials: true })
      .then((res) => {
        if (res.status === 200) {
          if (props.organizationId) {
            setOrganizationId(props.organizationId);
            setOrganizations(
              res.data.filter((org) => org.id === props.organizationId)
            );
          } else {
            setOrganizations(res.data);
          }
        }
      })
      .catch((error) => {
        console.error("Failed to get organizations", error);
      });
  }, [props.organizationId, setOrganizationId]);

  useEffect(() => {
    if (!organizationId || organizationId === "0") {
      setPatientId("0");
      return;
    }
    axios
      .get(`/api/v1/organizations/${organizationId}/patients`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 200) {
          setPatients(res.data.map((d) => d.callup_patient));
          if (props.patientId) {
            setPatientId(props.patientId);
          } else if (res.data.length === 0) {
            setPatientId("0");
          } else {
            setPatientId("");
          }
        }
      })
      .catch((error) => {
        console.error("Failed to get patients", error);
      });
  }, [organizationId, props.patientId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      organizationId,
      organizationName,
      organizationType,
      organizationAddressLine1,
      organizationAddressLine2,
      organizationCity,
      organizationCounty,
      organizationState,
      organizationZip,
      organizationPhone,
      callerId,
      callerFirstName,
      callerLastName,
      callerPhone,
      callerEmail,
      callerCredentials,
      callerRole,
      patientId,
      firstName,
      lastName,
      DOB,
      age,
      phone,
      email,
      race,
      gender,
      reasonForConsult,
      anxietyDisorders,
      depressiveDisordersExcludingPostpartumDepression,
      postpartumDepression,
      bipolarDisorders,
      adhd,
      autismSpectrumDisorder,
      intellectualDevelopmentalDisabilities,
      disruptiveImpulseControlConductDisorders,
      feedingEatingDisorders,
      obsessiveCompulsiveAndRelatedDisorders,
      traumaAndStressorRelatedDisorders,
      schizophreniaAndOtherPsychoticDisorders,
      substanceRelatedAlcohol,
      substanceRelatedMarijuana,
      substanceRelatedNicotine,
      substanceRelatedOpioid,
      substanceRelatedOthers,
      sleepDisorders,
      suicidalitySelfHarm,
      dementiaDelirium,
      otherCondition,
      communicationMethod,
      callbackTime,
      fosterCare,
    };
    try {
      const response = await axios.post("/api/v1/consults/employee", formData);
      if (response.status === 200) {
        navigate(`${pagePrefix}/dashboard`);
      }
    } catch (error) {
      console.error("Error:", error);
      alert(error.response.data.errors[0].msg);
    }
  };

  if (isLoggedIn === false) {
    return <Navigate to={`${pagePrefix}/login`} replace />;
  } else if (isLoggedIn) {
    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={8}>
            <h1 className="large text-primary">Request new consult</h1>
            <Form onSubmit={onSubmit}>
              <OrgRegistration
                organizations={organizations}
                organizationId={[organizationId, setOrganizationId]}
                organizationName={[organizationName, setOrganizationName]}
                organizationType={[organizationType, setOrganizationType]}
                organizationAddressLine1={[
                  organizationAddressLine1,
                  setOrganizationAddressLine1,
                ]}
                organizationAddressLine2={[
                  organizationAddressLine2,
                  setOrganizationAddressLine2,
                ]}
                organizationCity={[organizationCity, setOrganizationCity]}
                organizationCounty={[organizationCounty, setOrganizationCounty]}
                organizationState={[organizationState, setOrganizationState]}
                organizationZip={[organizationZip, setOrganizationZip]}
                organizationPhone={[organizationPhone, setOrganizationPhone]}
                passedPatient={passedPatient}
              />
              <CallerForm
                organizationId={organizationId}
                callerId={[callerId, setCallerId]}
                callerFirstName={[callerFirstName, setCallerFirstName]}
                callerLastName={[callerLastName, setCallerLastName]}
                callerPhone={[callerPhone, setCallerPhone]}
                callerEmail={[callerEmail, setCallerEmail]}
                callerCredentials={[callerCredentials, setCallerCredentials]}
                callerRole={[callerRole, setCallerRole]}
              />
              <PatientRegistration
                patients={patients}
                patientId={[patientId, setPatientId]}
                firstName={[firstName, setFirstName]}
                lastName={[lastName, setLastName]}
                DOB={[DOB, setDOB]}
                age={[age, setAge]}
                phone={[phone, setPhone]}
                email={[email, setEmail]}
                race={[race, setRace]}
                gender={[gender, setGender]}
                ethnicity={[ethnicity, setEthnicity]}
                MRN={[MRN, setMRN]}
                passedPatient={passedPatient}
                previousPatient={[false, () => {}]}
              />
              <NewConsultForm
                reasonForConsult={[reasonForConsult, setReasonForConsult]}
                anxietyDisorders={[anxietyDisorders, setAnxietyDisorders]}
                depressiveDisordersExcludingPostpartumDepression={[
                  depressiveDisordersExcludingPostpartumDepression,
                  setDepressiveDisordersExcludingPostpartumDepression,
                ]}
                postpartumDepression={[
                  postpartumDepression,
                  setPostpartumDepression,
                ]}
                bipolarDisorders={[bipolarDisorders, setBipolarDisorders]}
                adhd={[adhd, setAdhd]}
                autismSpectrumDisorder={[
                  autismSpectrumDisorder,
                  setAutismSpectrumDisorder,
                ]}
                intellectualDevelopmentalDisabilities={[
                  intellectualDevelopmentalDisabilities,
                  setIntellectualDevelopmentalDisabilities,
                ]}
                disruptiveImpulseControlConductDisorders={[
                  disruptiveImpulseControlConductDisorders,
                  setDisruptiveImpulseControlConductDisorders,
                ]}
                feedingEatingDisorders={[
                  feedingEatingDisorders,
                  setFeedingEatingDisorders,
                ]}
                obsessiveCompulsiveAndRelatedDisorders={[
                  obsessiveCompulsiveAndRelatedDisorders,
                  setObsessiveCompulsiveAndRelatedDisorders,
                ]}
                traumaAndStressorRelatedDisorders={[
                  traumaAndStressorRelatedDisorders,
                  setTraumaAndStressorRelatedDisorders,
                ]}
                schizophreniaAndOtherPsychoticDisorders={[
                  schizophreniaAndOtherPsychoticDisorders,
                  setSchizophreniaAndOtherPsychoticDisorders,
                ]}
                substanceRelatedAlcohol={[
                  substanceRelatedAlcohol,
                  setSubstanceRelatedAlcohol,
                ]}
                substanceRelatedMarijuana={[
                  substanceRelatedMarijuana,
                  setSubstanceRelatedMarijuana,
                ]}
                substanceRelatedNicotine={[
                  substanceRelatedNicotine,
                  setSubstanceRelatedNicotine,
                ]}
                substanceRelatedOpioid={[
                  substanceRelatedOpioid,
                  setSubstanceRelatedOpioid,
                ]}
                substanceRelatedOthers={[
                  substanceRelatedOthers,
                  setSubstanceRelatedOthers,
                ]}
                sleepDisorders={[sleepDisorders, setSleepDisorders]}
                suicidalitySelfHarm={[
                  suicidalitySelfHarm,
                  setSuicidalitySelfHarm,
                ]}
                dementiaDelirium={[dementiaDelirium, setDementiaDelirium]}
                otherCondition={[otherCondition, setOtherCondition]}
                communicationMethod={[
                  communicationMethod,
                  setCommunicationMethod,
                ]}
                callbackTime={[callbackTime, setCallbackTime]}
                fosterCare={[fosterCare, setFosterCare]}
              />
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <Container className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }
};

export default NewConsultEmployee;
