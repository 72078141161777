import axios from "axios";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { toReadableDate } from "../../utils/toReadableDate";
import { useAuth } from "../layout/AuthProvider";
import ConsultQuestionAnswered from "./ConsultQuestionAnswered";

const ConsultComments = (props) => {
  const { isLoggedIn } = useAuth();
  const consult = props.consult;
  const setRefresh = props.setRefresh;
  const setRefreshable = props.setRefreshable;
  const [content, setContent] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    if (content.length === 0) {
      alert("Comment cannot be empty");
      return;
    }
    formData.append("content", content);
    axios
      .post(`/api/v1/consults/${consult.id}/comment`, { content: content })
      .then((res) => {
        if (res.status === 200) {
          setRefresh((prev) => !prev);
          setContent("");
          setRefreshable(true);
        }
      })
      .catch((error) => {
        console.error("Failed to create comment", error);
      });
  };

  const handleContentChange = (e) => {
    const value = e.target.value;
    setContent(value);
    if (value !== "") {
      setRefreshable(false);
    } else {
      setRefreshable(true);
    }
  };

  const renderCommentForm = () => {
    if (
      (isLoggedIn === "consultant" || isLoggedIn === "consumer") &&
      consult.status === "open"
    ) {
      return (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="commentTextarea" className="pb-2">
            <Form.Control
              as="textarea"
              value={content}
              onChange={(event) => handleContentChange(event)}
              placeholder="Enter your comment..."
            />
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button variant="primary" type="submit">
              Send Message
            </Button>
          </div>
        </Form>
      );
    }
    return null;
  };

  const isRightAligned = (type) => {
    if (
      isLoggedIn === type ||
      (type === "consultant" && isLoggedIn === "admin")
    ) {
      return "text-end";
    }
    return "text-start";
  };

  return (
    <div className="mb-4">
      <h2>Consult Discussion/Chat</h2>
      <ConsultQuestionAnswered consult={consult} setRefresh={setRefresh} />
      <hr />
      {consult.callup_comments.length === 0 ? (
        <p>No comments yet</p>
      ) : (
        consult.callup_comments.map((comment) => (
          <div
            key={comment.id}
            className={isRightAligned(comment.callup_user.type)}
          >
            <p>{comment.content}</p>
            <p className="text-secondary">
              {comment.callup_user.firstName} {comment.callup_user.lastName} -{" "}
              {toReadableDate(comment.createdAt)}
            </p>
            <hr />
          </div>
        ))
      )}
      {renderCommentForm()}
    </div>
  );
};

export default ConsultComments;
