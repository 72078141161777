import React from "react";
import { Dropdown } from "react-bootstrap";

import { toReadableDate } from "../../utils/toReadableDate";
import { pagePrefix } from "../../utils/pagePrefix";

const RelatedConsults = (props) => {
  const relatedConsults = props.relatedConsults;

  const renderRelatedConsults = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="info" id="dropdown-basic">
          Related Consults
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {relatedConsults.map((consult) => (
            <Dropdown.Item
              key={consult.id}
              href={`${pagePrefix}/consult/${consult.id}`}
              target="_blank"
            >
              {consult.callup_patient.firstName}{" "}
              {consult.callup_patient.lastName} at{" "}
              {toReadableDate(consult.createdAt)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  if (!relatedConsults) {
    return null;
  } else {
    return <>{renderRelatedConsults()}</>;
  }
};

export default RelatedConsults;
