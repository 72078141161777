import React from "react";
import { Form } from "react-bootstrap";
import ExistingPatientsDropdown from "./ExistingPatientsDropdown";

import { useAuth } from "../layout/AuthProvider";
import { Asterisk } from "../utils/Asterisk";

const PatientRegistration = (props) => {
  const { isLoggedIn, userStatus } = useAuth();
  const patients = props.patients;
  const passedPatient = props.passedPatient;
  const [patientId, setPatientId] = props.patientId;
  const [firstName, setFirstName] = props.firstName;
  const [lastName, setLastName] = props.lastName;
  const [DOB, setDOB] = props.DOB;
  const [age, setAge] = props.age;
  const [phone, setPhone] = props.phone;
  const [email, setEmail] = props.email;
  const [race, setRace] = props.race;
  const [gender, setGender] = props.gender;
  const [ethnicity, setEthnicity] = props.ethnicity;
  const [MRN, setMRN] = props.MRN;
  const [previousPatient, setPreviousPatient] = props.previousPatient;

  const handleAge = (dob) => {
    setDOB(dob);
    const today = new Date();
    const birthDate = new Date(dob);
    let a = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      a--;
    }
    setAge(a);
  };

  const isPreviousPatient = () => {
    if (isLoggedIn === "consumer" && userStatus !== "approved") {
      return (
        <Form.Group controlId="existingPatient" className="mb-3">
          <Form.Check
            type="checkbox"
            label="Might this patient have been seen before?"
            name="existingPatient"
            value={previousPatient}
            onChange={(e) => setPreviousPatient(e.target.checked)}
          />
        </Form.Group>
      );
    }
    return null;
  };

  const renderPatientFields = () => {
    if (patientId === "0" || patients.length === 0) {
      return (
        <>
          {isPreviousPatient()}
          <Form.Group controlId="firstName" className="mb-3">
            <Form.Label>
              First Name: <Asterisk />
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="First Name"
              name="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="lastName" className="mb-3">
            <Form.Label>
              Last Name: <Asterisk />
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Last Name"
              name="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Label>Date of Birth</Form.Label>
          <Form.Group controlId="DOB" className="mb-3">
            <Form.Control
              type="date"
              placeholder="Date of Birth"
              name="DOB"
              value={DOB}
              onChange={(e) => handleAge(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="age" className="mb-3">
            <Form.Label>
              Age (Calculated from Date of Birth, clear if needed) <Asterisk />
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Age"
              name="age"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="phone" className="mb-3">
            <Form.Control
              type="tel"
              placeholder="Phone (Patient or Gaurdian if patient is under 18 years of age)"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="email" className="mb-3">
            <Form.Control
              type="email"
              placeholder="Email (Patient or Gaurdian if patient is under 18 years of age)"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="gender" className="mb-3">
            <Form.Select
              name="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Transgender (MTF)">Transgender (MTF)</option>
              <option value="Transgender (FTM)">Transgender (FTM)</option>
              <option value="Non-binary">Non-binary</option>
              <option value="Choose not to disclose">
                Choose not to disclose
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="race" className="mb-3">
            <Form.Select
              name="race"
              value={race}
              onChange={(e) => setRace(e.target.value)}
            >
              <option value="">Select Race</option>
              <option value="American Indian or Alaska Native">
                American Indian or Alaska Native
              </option>
              <option value="Asian">Asian</option>
              <option value="Black or African American">
                Black or African American
              </option>
              <option value="Native Hawaiian">Native Hawaiian</option>
              <option value="Other Pacific Islander">
                Other Pacific Islander
              </option>
              <option value="White or Caucasian">White or Caucasian</option>
              <option value="More than one race">More than one race</option>
              <option value="Other">Other</option>
              <option value="Choose not to disclose">
                Choose not to disclose
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="ethnicity" className="mb-3">
            <Form.Select
              name="ethnicity"
              value={ethnicity}
              onChange={(e) => setEthnicity(e.target.value)}
            >
              <option value="">Select Ethnicity</option>
              <option value="Hispanic/Latino">Hispanic/Latino</option>
              <option value="Non Hispanic/Latino">Non Hispanic/Latino</option>
              <option value="Choose not to disclose">
                Choose not to disclose
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="mrn" className="mb-3">
            <Form.Control
              type="text"
              placeholder="Patient MRN from provider chart"
              name="mrn"
              value={MRN}
              onChange={(e) => setMRN(e.target.value)}
            />
          </Form.Group>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <h4>Patient Information:</h4>
      {isLoggedIn === "consumer" && userStatus !== "approved" ? null : (
        <ExistingPatientsDropdown
          registration={true}
          patients={patients}
          patientId={[patientId, setPatientId]}
          passedPatient={passedPatient}
        />
      )}
      {renderPatientFields()}
    </>
  );
};

export default PatientRegistration;
