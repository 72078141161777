import React from "react";
import { Form } from "react-bootstrap";

const UserRole = (props) => {
  const [roleType, setRoleType] = props.roleType;

  return (
    <Form.Group className="mb-3">
      <Form.Select
        name="roleType"
        value={roleType}
        onChange={(e) => setRoleType(e.target.value)}
        required
      >
        <option value="">Select Role</option>
        <option value="Family Medicine">Family Medicine</option>
        <option value="Pediatrician">Pediatrician</option>
        <option value="OB/GYN">OB/GYN</option>
        <option value="Internal Medicine">Internal Medicine</option>
        <option value="Psychiatrist">Psychiatrist</option>
        <option value="Developmental-Behavioral Pediatrician">
          Developmental-Behavioral Pediatrician
        </option>
        <option value="Therapist">Therapist</option>
        <option value="Care coordinator/patient navigator">
          Care coordinator/patient navigator
        </option>
        <option value="School Psychologist/Counselor">
          School Psychologist/Counselor
        </option>
        <option value="School Staff">School Staff</option>
        <option value="Emergency Medicine">Emergency Medicine</option>
        <option value="Crisis Worker">Crisis Worker</option>
        <option value="Other">Other</option>
        <option value="Unknown">Unknown</option>
      </Form.Select>
    </Form.Group>
  );
};

export default UserRole;
