import axios from "axios";
import React, { useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";

import { useAuth } from "../layout/AuthProvider";

const Referrals = (props) => {
  const { isLoggedIn } = useAuth();
  const consult = props.consult;
  const setRefresh = props.setRefresh;
  const setRefreshable = props.setRefreshable;
  const [showModal, setShowModal] = useState(false);
  const [referringTo, setReferringTo] = useState("");
  const [description, setDescription] = useState("");
  const [referralId, setReferralId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleHide = () => {
    setShowModal(false);
  };

  const handleHideEdit = () => {
    setShowEditModal(false);
    setReferralId(null);
    setReferringTo("");
    setDescription("");
    setRefreshable(true);
  };

  const handleShowEdit = (referral) => {
    setReferralId(referral.id);
    setReferringTo(referral.referringTo);
    setDescription(referral.description);
    setShowEditModal(true);
    setRefreshable(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!referringTo || referringTo === "") {
      alert("Please enter who the referral is for");
      return;
    }
    try {
      const response = await axios.post(
        `/api/v1/consults/${consult.id}/referral`,
        {
          referringTo: referringTo,
          description: description,
        }
      );
      if (response.status === 200) {
        setRefresh((prev) => !prev);
        setShowModal(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        alert("You are not permitted to create a referral.");
      } else {
        alert("Failed to create referral");
      }
      console.error("Failed to create referral", error);
    }
  };

  const updateHealthEquity = async (id, improvedHealthEquity) => {
    try {
      if (improvedHealthEquity === "Select...") {
        return;
      }
      const response = await axios.post(`/api/v1/referrals/${id}/equity`, {
        improvedHealthEquity: improvedHealthEquity,
      });
      if (response.status === 200) {
        setRefresh((prev) => !prev);
      }
    } catch (error) {
      if (error.response.status === 401) {
        alert("You are not permitted to update health equity.");
      } else {
        alert("Failed to update health equity");
      }
      console.error("Failed to update health equity", error);
    }
  };

  const renderNewReferralElements = () => {
    if (isLoggedIn !== "consultant") {
      return null;
    }
    return (
      <>
        {renderNewReferralModal()}
        <Button onClick={() => setShowModal(true)}>Create Referral</Button>
      </>
    );
  };

  const renderReferralTable = () => {
    if (!consult.callup_referrals || consult.callup_referrals.length === 0) {
      return <p>No referrals found</p>;
    }
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Referrer</th>
            <th>Referring To</th>
            <th>Description</th>
            {isLoggedIn === "consultant" || isLoggedIn === "admin" ? (
              <th>Did this referral improve health equity?</th>
            ) : null}
            {isLoggedIn === "consultant" ? <th>Edit</th> : null}
          </tr>
        </thead>
        <tbody>
          {consult.callup_referrals.map((referral) => {
            if (referral.status === "deleted") {
              return null;
            }
            return (
              <tr key={referral.id}>
                <td>
                  {referral.referrer.firstName} {referral.referrer.lastName}
                </td>
                <td>{referral.referringTo}</td>
                <td>{referral.description}</td>
                {isLoggedIn === "consultant" || isLoggedIn === "admin" ? (
                  <td>
                    <Form>
                      <Form.Select
                        type="checkbox"
                        value={referral.improvedHealthEquity}
                        onChange={(e) =>
                          updateHealthEquity(referral.id, e.target.value)
                        }
                      >
                        <option value={null}>Select...</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </Form.Select>
                    </Form>
                  </td>
                ) : null}
                {isLoggedIn === "consultant" ? (
                  <th>
                    <Button onClick={() => handleShowEdit(referral)}>
                      Edit
                    </Button>
                  </th>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const renderNewReferralModal = () => {
    return (
      <Modal show={showModal} onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title>Create Referral</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Referring To</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter who the referral is for"
                onChange={(e) => setReferringTo(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter a description of the referral"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="mt-2"
              onClick={onSubmit}
            >
              Create Referral
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  const renderEditReferralModal = () => {
    return (
      <Modal show={showEditModal} onHide={handleHideEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Referral</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Referring To</Form.Label>
              <Form.Control
                type="text"
                value={referringTo}
                placeholder="Enter who the referral is for"
                onChange={(e) => setReferringTo(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={description}
                placeholder="Enter a description of the referral"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="mt-2"
              onClick={updateReferral}
            >
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={deleteReferral}>
            Delete Referral
          </Button>
          <Button variant="secondary" onClick={handleHideEdit}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const updateReferral = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `/api/v1/referrals/${referralId}/update`,
        {
          referringTo: referringTo,
          description: description,
        }
      );
      if (response.status === 200) {
        setRefresh((prev) => !prev);
        handleHideEdit();
      }
    } catch (error) {
      if (error.response.status === 401) {
        alert("You are not permitted to update a referral.");
      } else {
        alert("Failed to update referral");
      }
      console.error("Failed to update referral", error);
    }
  };

  const deleteReferral = async (e) => {
    console.log("Delete referral");
    e.preventDefault();
    try {
      const response = await axios.put(
        `/api/v1/referrals/${referralId}/delete`
      );
      if (response.status === 200) {
        setRefresh((prev) => !prev);
        handleHideEdit();
      }
    } catch (error) {
      if (error.response.status === 401) {
        alert("You are not permitted to delete a referral.");
      } else {
        alert("Failed to delete referral");
      }
      console.error("Failed to delete referral", error);
    }
  };

  return (
    <div className="mb-4">
      <h4>Referrals</h4>
      {renderReferralTable()}
      {renderNewReferralElements()}
      {renderEditReferralModal()}
    </div>
  );
};

export default Referrals;
