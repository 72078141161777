import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Form, Button, Container, Row, Col } from "react-bootstrap";

import OrgRegistration from "../../Orgs/OrgRegistration";
import { pagePrefix } from "../../../utils/pagePrefix";

const OrgRegisterAdmin = () => {
  const organizations = "";
  const [organizationId, setOrganizationId] = useState("0");
  const [organizationName, setOrganizationName] = useState();
  const [organizationType, setOrganizationType] = useState();
  const [organizationAddressLine1, setOrganizationAddressLine1] = useState();
  const [organizationAddressLine2, setOrganizationAddressLine2] = useState();
  const [organizationCity, setOrganizationCity] = useState();
  const [organizationCounty, setOrganizationCounty] = useState();
  const [organizationState, setOrganizationState] = useState("UT");
  const [organizationZip, setOrganizationZip] = useState();
  const [organizationPhone, setOrganizationPhone] = useState();
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      organizationName,
      organizationType,
      organizationAddressLine1,
      organizationAddressLine2,
      organizationCity,
      organizationCounty,
      organizationState,
      organizationZip,
      organizationPhone,
    };
    try {
      const res = await axios.post("/api/v1/organizations", formData);
      if (res.status === 200) {
        navigate(`${pagePrefix}/admin/organizations`);
      }
    } catch (err) {
      const errorMessage = err.response.data.errors[0].msg;
      alert(errorMessage);
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <h1 className="large text-primary">Register Organization</h1>
          <Form onSubmit={onSubmit}>
            <OrgRegistration
              organizations={organizations}
              organizationId={[organizationId, setOrganizationId]}
              organizationName={[organizationName, setOrganizationName]}
              organizationType={[organizationType, setOrganizationType]}
              organizationAddressLine1={[
                organizationAddressLine1,
                setOrganizationAddressLine1,
              ]}
              organizationAddressLine2={[
                organizationAddressLine2,
                setOrganizationAddressLine2,
              ]}
              organizationCity={[organizationCity, setOrganizationCity]}
              organizationCounty={[organizationCounty, setOrganizationCounty]}
              organizationState={[organizationState, setOrganizationState]}
              organizationZip={[organizationZip, setOrganizationZip]}
              organizationPhone={[organizationPhone, setOrganizationPhone]}
            />
            <Button variant="primary" type="submit">
              Register
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default OrgRegisterAdmin;
