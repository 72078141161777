import React, { useEffect, useState } from "react";
import axios from "axios";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { toReadableDate } from "../../utils/toReadableDate";
import { pagePrefix } from "../../utils/pagePrefix";
import { isLastCommentFromOtherParty } from "../../utils/isLastCommentFromOtherParty";
import { useAuth } from "../layout/AuthProvider";

const ReservedConsultsTable = () => {
  const { isLoggedIn } = useAuth();
  const [consults, setConsults] = useState([]);

  useEffect(() => {
    axios
      .get("/api/v1/consults/reserved", { withCredentials: true })
      .then((res) => {
        if (res.status === 200) {
          setConsults(res.data);
        }
      })
      .catch((error) => {
        console.error("Failed to get consults", error);
      });
  }, []);

  const renderConsults = () => {
    if (consults.length === 0) {
      return <tr></tr>;
    }
    return consults.map((consult) => {
      return (
        <tr key={consult.id}>
          <td>
            {isLastCommentFromOtherParty(consult, isLoggedIn) ? (
              <>
                <FontAwesomeIcon
                  icon="fa-solid fa-circle"
                  style={{ color: "#ff0000" }}
                />{" "}
                <br />
              </>
            ) : null}
            {consult.id}
          </td>
          <td>
            {consult.callup_patient.firstName} {consult.callup_patient.lastName}
          </td>
          <td>{toReadableDate(consult.createdAt)}</td>
          <td>
            {consult.createdByRole.callup_user.lastName} at{" "}
            {consult.callup_organization.name}
          </td>
          <td>
            <LinkContainer to={`${pagePrefix}/consult/${consult.id}`}>
              <Button variant="primary" size="sm">
                View Consult
              </Button>
            </LinkContainer>
          </td>
        </tr>
      );
    });
  };
  return (
    <>
      <h4>Your Reserved Consults</h4>
      <Table striped bordered hover responsive="sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>Patient Name</th>
            <th>Requested At</th>
            <th>Requester</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>{renderConsults()}</tbody>
      </Table>
    </>
  );
};

export default ReservedConsultsTable;
