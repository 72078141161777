import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { pagePrefix } from "../../utils/pagePrefix";

const ConsultStatusManager = (props) => {
  const consult = props.consult;
  const setRefresh = props.setRefresh;
  const [status, setStatus] = useState(consult.status);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const saveStatus = async () => {
    try {
      const response = await axios.post(
        `/api/v1/consults/${consult.id}/status`,
        {
          status: status,
        }
      );
      if (response.status === 200) {
        setRefresh((prev) => !prev);
        setShowModal(false);
        if (status === "closed" || status === "cancelled") {
          navigate(`${pagePrefix}/dashboard`);
        }
      } else {
        console.error("Failed to save status");
      }
    } catch (error) {
      console.error("Error saving status:", error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setStatus(consult.status);
  };

  const handleOpenModal = (s) => {
    setShowModal(true);
    setStatus(s);
  };

  const renderStatusModal = () => {
    return (
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Change Consult Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="status">
              <Form.Label>Please confirm the change:</Form.Label>
              <Form.Select
                name="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                required
              >
                <option value="open">Open</option>
                <option value="closed">Closed</option>
                <option value="cancelled">Cancelled</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary" onClick={saveStatus}>
            Save
          </Button>
          <Button className="btn btn-secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <Form className="mb-4">
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Status:
          </Form.Label>
          <Col sm={4}>
            <Form.Select
              name="status"
              value={status}
              onChange={(e) => handleOpenModal(e.target.value)}
            >
              <option value="open">Open</option>
              <option value="closed">Closed</option>
              <option value="cancelled">Cancelled</option>
            </Form.Select>
          </Col>
        </Form.Group>
      </Form>
      {renderStatusModal()}
    </>
  );
};

export default ConsultStatusManager;
