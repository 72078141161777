import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useAuth } from "../layout/AuthProvider";
import { pagePrefix } from "../../utils/pagePrefix";

const Login = () => {
  const { isLoggedIn, setLoginState } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = { email, password };
    try {
      const res = await axios.post("/api/v1/auth", formData);
      let user = res.data.user;
      if (user) {
        setLoginState(true);
        navigate(`${pagePrefix}/dashboard`);
      }
    } catch (err) {
      alert("Authentication failed");
    }
  };

  const handleSamlLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/api/v1/auth/employee`;
  };

  if (isLoggedIn) {
    navigate(`${pagePrefix}/dashboard`);
  }
  return (
    <Container>
      {/** <Button onClick={handleSamlLogin}>Login as Employee</Button> */}
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <h1 className="large text-primary">Login</h1>
          <p className="lead">
            <i className="fas fa-user"></i> Sign Into Your Account
          </p>
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="email"
                placeholder="Email Address"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                type="password"
                placeholder="Password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                minLength="6"
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Login
            </Button>
          </Form>
          <p className="my-1">
            Don't have an account?{" "}
            <Link to={`${pagePrefix}/register`}>Sign Up</Link>
          </p>
          <p className="my-1">
            Forgot your password?{" "}
            <Link to={`${pagePrefix}/password/reset/initiate`}>Click Here</Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
