export const isLastCommentFromOtherParty = (consult, isLoggedIn) => {
  if (
    isLoggedIn === false ||
    !consult.callup_comments ||
    consult.callup_comments.length === 0
  ) {
    return false;
  } else if (isLoggedIn === "consumer") {
    const lastComment =
      consult.callup_comments[consult.callup_comments.length - 1];
    return lastComment.callup_user.type === "consultant";
  } else if (isLoggedIn === "consultant" || isLoggedIn === "admin") {
    const lastComment =
      consult.callup_comments[consult.callup_comments.length - 1];
    return lastComment.callup_user.type === "consumer";
  }
};
