import React from "react";
import { Form } from "react-bootstrap";
import ExistingOrgsDropdown from "./ExistingOrgsDropdown";

import { Asterisk } from "../utils/Asterisk";

const OrgRegistration = (props) => {
  const organizations = props.organizations;
  const passedPatient = props.passedPatient;
  const [organizationId, setOrganizationId] = props.organizationId;
  const [organizationName, setOrganizationName] = props.organizationName;
  const [organizationType, setOrganizationType] = props.organizationType;
  const [organizationAddressLine1, setOrganizationAddressLine1] =
    props.organizationAddressLine1;
  const [organizationAddressLine2, setOrganizationAddressLine2] =
    props.organizationAddressLine2;
  const [organizationCity, setOrganizationCity] = props.organizationCity;
  const [organizationState, setOrganizationState] = props.organizationState;
  const [organizationZip, setOrganizationZip] = props.organizationZip;
  const [organizationCounty, setOrganizationCounty] = props.organizationCounty;
  const [organizationPhone, setOrganizationPhone] = props.organizationPhone;

  const renderOrganizationFields = () => {
    if (organizationId === "0" || organizations.length === 0) {
      return (
        <div>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Organization Name"
              name="organizationName"
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Select
              name="organizationType"
              value={organizationType}
              onChange={(e) => setOrganizationType(e.target.value)}
              required
            >
              <option value="">Select Organization Type</option>
              <option value="School">School</option>
              <option value="Primary Care Practice">
                Primary Care Practice
              </option>
              <option value="Emergency Department">Emergency Department</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Address Line 1"
              name="organizationAddressLine1"
              value={organizationAddressLine1}
              onChange={(e) => setOrganizationAddressLine1(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Address Line 2 (Optional)"
              name="organizationAddressLine2"
              value={organizationAddressLine2}
              onChange={(e) => setOrganizationAddressLine2(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="City"
              name="organizationCity"
              value={organizationCity}
              onChange={(e) => setOrganizationCity(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Select
              name="organizationCounty"
              value={organizationCounty}
              onChange={(e) => setOrganizationCounty(e.target.value)}
              required
            >
              <option value="">Select County</option>
              <option value="Beaver">Beaver</option>
              <option value="Box Elder">Box Elder</option>
              <option value="Cache">Cache</option>
              <option value="Carbon">Carbon</option>
              <option value="Daggett">Daggett</option>
              <option value="Davis">Davis</option>
              <option value="Duchesne">Duchesne</option>
              <option value="Emery">Emery</option>
              <option value="Garfield">Garfield</option>
              <option value="Grand">Grand</option>
              <option value="Iron">Iron</option>
              <option value="Juab">Juab</option>
              <option value="Kane">Kane</option>
              <option value="Millard">Millard</option>
              <option value="Morgan">Morgan</option>
              <option value="Piute">Piute</option>
              <option value="Rich">Rich</option>
              <option value="Salt Lake">Salt Lake</option>
              <option value="San Juan">San Juan</option>
              <option value="Sanpete">Sanpete</option>
              <option value="Sevier">Sevier</option>
              <option value="Summit">Summit</option>
              <option value="Tooele">Tooele</option>
              <option value="Uintah">Uintah</option>
              <option value="Utah">Utah</option>
              <option value="Wasatch">Wasatch</option>
              <option value="Washington">Washington</option>
              <option value="Wayne">Wayne</option>
              <option value="Weber">Weber</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Select
              name="organizationState"
              value={organizationState}
              onChange={(e) => setOrganizationState(e.target.value)}
              required
            >
              <option value="UT">
                UT (This service is only available within the state of Utah)
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Zip"
              name="organizationZip"
              value={organizationZip}
              onChange={(e) => setOrganizationZip(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Phone"
              name="organizationPhone"
              value={organizationPhone}
              onChange={(e) => setOrganizationPhone(e.target.value)}
              required
            />
          </Form.Group>
        </div>
      );
    }
  };

  return (
    <>
      <h4>
        Organization Information: <Asterisk />
      </h4>
      <ExistingOrgsDropdown
        registration={true}
        organizations={organizations}
        organizationId={[organizationId, setOrganizationId]}
        passedPatient={passedPatient}
      />
      {renderOrganizationFields()}
    </>
  );
};

export default OrgRegistration;
