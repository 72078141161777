import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";

import { useAuth } from "./AuthProvider";
import { pagePrefix } from "../../utils/pagePrefix";
import config from "../../config/config.json";

const CustomNavbar = () => {
  const { isLoggedIn, first, setLoginState } = useAuth();
  const navigate = useNavigate();
  const navbarConfig = config[process.env.REACT_APP_ENV];

  const dashboardLink = (
    <LinkContainer to={`${pagePrefix}/dashboard`}>
      <Nav.Link>Dashboard</Nav.Link>
    </LinkContainer>
  );

  const newConsultLink = (
    <LinkContainer to={`${pagePrefix}/consult/new`}>
      <Nav.Link>New Consult</Nav.Link>
    </LinkContainer>
  );

  const oldConsultsLink = (
    <LinkContainer to={`${pagePrefix}/consults/old`}>
      <Nav.Link>Review Old Consults</Nav.Link>
    </LinkContainer>
  );

  const nameOfLoggedIn = () => {
    if (first) {
      return (
        <LinkContainer to={`${pagePrefix}/profile`}>
          <Nav.Link>{first}</Nav.Link>
        </LinkContainer>
      );
    }
    return null;
  };

  const handleLogout = async () => {
    try {
      await axios.put("/api/v1/auth/logout");
      setLoginState(false);
      window.location.href = `${pagePrefix}/login`;
    } catch (error) {
      console.error(error);
    }
  };

  const logoutLink = <Nav.Link onClick={handleLogout}>Logout</Nav.Link>;

  const loggedOutLinks = (
    <>
      <LinkContainer to={`${pagePrefix}/register`}>
        <Nav.Link>Register</Nav.Link>
      </LinkContainer>
      <LinkContainer to={`${pagePrefix}/login`}>
        <Nav.Link>Login</Nav.Link>
      </LinkContainer>
    </>
  );

  const consumerAndConsultantLinks = (
    <>
      {dashboardLink}
      {newConsultLink}
      {oldConsultsLink}
      {nameOfLoggedIn()}
      {logoutLink}
    </>
  );

  const callCenterLinks = (
    <>
      {dashboardLink}
      {newConsultLink}
      {nameOfLoggedIn()}
      {logoutLink}
    </>
  );

  const adminLinks = (
    <>
      {dashboardLink}
      {newConsultLink}
      {oldConsultsLink}
      <NavDropdown title="Admin" id="admin-dropdown">
        <LinkContainer to={`${pagePrefix}/admin/users`}>
          <NavDropdown.Item>Users</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to={`${pagePrefix}/admin/organizations`}>
          <NavDropdown.Item>Organizations</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to={`${pagePrefix}/admin/events`}>
          <NavDropdown.Item>Events</NavDropdown.Item>
        </LinkContainer>
      </NavDropdown>
      {nameOfLoggedIn()}
      {logoutLink}
    </>
  );

  const renderLinks = () => {
    if (!isLoggedIn) {
      return loggedOutLinks;
    } else if (isLoggedIn === "consumer" || isLoggedIn === "consultant") {
      return consumerAndConsultantLinks;
    } else if (isLoggedIn === "call_center") {
      return callCenterLinks;
    } else if (isLoggedIn === "admin") {
      return adminLinks;
    }
  };

  return (
    <Navbar expand="lg" className={`${navbarConfig.navbarColor}`}>
      <Container>
        <LinkContainer to={`${pagePrefix}`}>
          <Navbar.Brand>{navbarConfig.navbarBrand}</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">{renderLinks()}</Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
