import React from "react";
import { Form } from "react-bootstrap";

import { Asterisk } from "../utils/Asterisk";

const NewConsultForm = (props) => {
  const [reasonForConsult, setReasonForConsult] = props.reasonForConsult;
  const [anxietyDisorders, setAnxietyDisorders] = props.anxietyDisorders;
  const [
    depressiveDisordersExcludingPostpartumDepression,
    setDepressiveDisordersExcludingPostpartumDepression,
  ] = props.depressiveDisordersExcludingPostpartumDepression;
  const [postpartumDepression, setPostpartumDepression] =
    props.postpartumDepression;
  const [bipolarDisorders, setBipolarDisorders] = props.bipolarDisorders;
  const [adhd, setAdhd] = props.adhd;
  const [autismSpectrumDisorder, setAutismSpectrumDisorder] =
    props.autismSpectrumDisorder;
  const [
    intellectualDevelopmentalDisabilities,
    setIntellectualDevelopmentalDisabilities,
  ] = props.intellectualDevelopmentalDisabilities;
  const [
    disruptiveImpulseControlConductDisorders,
    setDisruptiveImpulseControlConductDisorders,
  ] = props.disruptiveImpulseControlConductDisorders;
  const [feedingEatingDisorders, setFeedingEatingDisorders] =
    props.feedingEatingDisorders;
  const [
    obsessiveCompulsiveAndRelatedDisorders,
    setObsessiveCompulsiveAndRelatedDisorders,
  ] = props.obsessiveCompulsiveAndRelatedDisorders;
  const [
    traumaAndStressorRelatedDisorders,
    setTraumaAndStressorRelatedDisorders,
  ] = props.traumaAndStressorRelatedDisorders;
  const [
    schizophreniaAndOtherPsychoticDisorders,
    setSchizophreniaAndOtherPsychoticDisorders,
  ] = props.schizophreniaAndOtherPsychoticDisorders;
  const [substanceRelatedAlcohol, setSubstanceRelatedAlcohol] =
    props.substanceRelatedAlcohol;
  const [substanceRelatedMarijuana, setSubstanceRelatedMarijuana] =
    props.substanceRelatedMarijuana;
  const [substanceRelatedNicotine, setSubstanceRelatedNicotine] =
    props.substanceRelatedNicotine;
  const [substanceRelatedOpioid, setSubstanceRelatedOpioid] =
    props.substanceRelatedOpioid;
  const [substanceRelatedOthers, setSubstanceRelatedOthers] =
    props.substanceRelatedOthers;
  const [sleepDisorders, setSleepDisorders] = props.sleepDisorders;
  const [suicidalitySelfHarm, setSuicidalitySelfHarm] =
    props.suicidalitySelfHarm;
  const [dementiaDelirium, setDementiaDelirium] = props.dementiaDelirium;
  const [otherCondition, setOtherCondition] = props.otherCondition;
  const [communicationMethod, setCommunicationMethod] =
    props.communicationMethod;
  const [callbackTime, setCallbackTime] = props.callbackTime;
  const [fosterCare, setFosterCare] = props.fosterCare;

  const renderConsultForm = () => {
    return (
      <>
        <Form.Group className="mb-3" controlId="communicationMethod">
          <Form.Label>
            Preferred Communication Method: <Asterisk />
          </Form.Label>
          <Form.Select
            name="communicationMethod"
            value={communicationMethod}
            onChange={(e) => setCommunicationMethod(e.target.value)}
            required
          >
            <option value="">Select Communication Method</option>
            <option value="phone">
              Phone → Receive a call back from the psychiatrist
            </option>
            <option value="online">
              Online → Use online messaging through (through Consult dashboard)
            </option>
          </Form.Select>
        </Form.Group>
        {communicationMethod !== "phone" ? null : (
          <Form.Group className="mb-3">
            <Form.Label>Best times to call:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Best times to call"
              name="callbackTime"
              value={callbackTime}
              onChange={(e) => setCallbackTime(e.target.value)}
            />
          </Form.Group>
        )}
        <Form.Group controlId="fosterCare" className="mb-3">
          <Form.Label>Is the patient in foster care?</Form.Label>
          <Form.Select
            name="fosterCare"
            value={fosterCare}
            onChange={(e) => setFosterCare(e.target.value)}
          >
            <option value="">Select Foster Care Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Unknown">Unknown</option>
          </Form.Select>
        </Form.Group>
        <Form.Group controlId="conditions" className="mb-3">
          <Form.Label>Presenting Problems:</Form.Label>
          <Form.Check
            type="checkbox"
            label="Anxiety disorders"
            name="anxietyDisorders"
            value={anxietyDisorders}
            onChange={(e) => setAnxietyDisorders(e.target.checked)}
          />
          <Form.Check
            type="checkbox"
            label="Depressive disorders (excluding postpartum depression)"
            name="depressiveDisordersExcludingPostpartumDepression"
            value={depressiveDisordersExcludingPostpartumDepression}
            onChange={(e) =>
              setDepressiveDisordersExcludingPostpartumDepression(
                e.target.checked
              )
            }
          />
          <Form.Check
            type="checkbox"
            label="Postpartum depression"
            name="postpartumDepression"
            value={postpartumDepression}
            onChange={(e) => setPostpartumDepression(e.target.checked)}
          />
          <Form.Check
            type="checkbox"
            label="Bipolar and related disorders"
            name="bipolarDisorders"
            value={bipolarDisorders}
            onChange={(e) => setBipolarDisorders(e.target.checked)}
          />
          <Form.Check
            type="checkbox"
            label="Attention-Deficit/Hyperactivity Disorder (ADHD)"
            name="adhd"
            value={adhd}
            onChange={(e) => setAdhd(e.target.checked)}
          />
          <Form.Check
            type="checkbox"
            label="Autism Spectrum Disorder"
            name="autismSpectrumDisorder"
            value={autismSpectrumDisorder}
            onChange={(e) => setAutismSpectrumDisorder(e.target.checked)}
          />
          <Form.Check
            type="checkbox"
            label="Intellectual/Developmental disability and related behaviors"
            name="intellectualDevelopmentalDisabilities"
            value={intellectualDevelopmentalDisabilities}
            onChange={(e) =>
              setIntellectualDevelopmentalDisabilities(e.target.checked)
            }
          />
          <Form.Check
            type="checkbox"
            label="Disruptive, impulse-control, and conduct disorders"
            name="disruptiveImpulseControlConductDisorders"
            value={disruptiveImpulseControlConductDisorders}
            onChange={(e) =>
              setDisruptiveImpulseControlConductDisorders(e.target.checked)
            }
          />
          <Form.Check
            type="checkbox"
            label="Feeding and eating disorders"
            name="feedingEatingDisorders"
            value={feedingEatingDisorders}
            onChange={(e) => setFeedingEatingDisorders(e.target.checked)}
          />
          <Form.Check
            type="checkbox"
            label="Obsessive-compulsive and related disorders"
            name="obsessiveCompulsiveAndRelatedDisorders"
            value={obsessiveCompulsiveAndRelatedDisorders}
            onChange={(e) =>
              setObsessiveCompulsiveAndRelatedDisorders(e.target.checked)
            }
          />
          <Form.Check
            type="checkbox"
            label="Trauma and stressor-related disorders"
            name="traumaAndStressorRelatedDisorders"
            value={traumaAndStressorRelatedDisorders}
            onChange={(e) =>
              setTraumaAndStressorRelatedDisorders(e.target.checked)
            }
          />
          <Form.Check
            type="checkbox"
            label="Schizophrenia spectrum and other psychotic disorders"
            name="schizophreniaAndOtherPsychoticDisorders"
            value={schizophreniaAndOtherPsychoticDisorders}
            onChange={(e) =>
              setSchizophreniaAndOtherPsychoticDisorders(e.target.checked)
            }
          />
          <Form.Check
            type="checkbox"
            label="Substance-related (alcohol)"
            name="substanceRelatedAlcohol"
            value={substanceRelatedAlcohol}
            onChange={(e) => setSubstanceRelatedAlcohol(e.target.checked)}
          />
          <Form.Check
            type="checkbox"
            label="Substance-related (marijuana)"
            name="substanceRelatedMarijuana"
            value={substanceRelatedMarijuana}
            onChange={(e) => setSubstanceRelatedMarijuana(e.target.checked)}
          />
          <Form.Check
            type="checkbox"
            label="Substance-related (nicotine)"
            name="substanceRelatedNicotine"
            value={substanceRelatedNicotine}
            onChange={(e) => setSubstanceRelatedNicotine(e.target.checked)}
          />
          <Form.Check
            type="checkbox"
            label="Substance-related (opioid)"
            name="substanceRelatedOpioid"
            value={substanceRelatedOpioid}
            onChange={(e) => setSubstanceRelatedOpioid(e.target.checked)}
          />
          <Form.Check
            type="checkbox"
            label="Substance-related (others)"
            name="substanceRelatedOthers"
            value={substanceRelatedOthers}
            onChange={(e) => setSubstanceRelatedOthers(e.target.checked)}
          />
          <Form.Check
            type="checkbox"
            label="Sleep disorders"
            name="sleepDisorders"
            value={sleepDisorders}
            onChange={(e) => setSleepDisorders(e.target.checked)}
          />
          <Form.Check
            type="checkbox"
            label="Suicidality or self-harm"
            name="suicidalitySelfHarm"
            value={suicidalitySelfHarm}
            onChange={(e) => setSuicidalitySelfHarm(e.target.checked)}
          />
          <Form.Check
            type="checkbox"
            label="Dementia/Delirium induced by a medial condition"
            name="dementiaDelirium"
            value={dementiaDelirium}
            onChange={(e) => setDementiaDelirium(e.target.checked)}
          />
        </Form.Group>
        <Form.Label>Other Presenting Problems</Form.Label>
        <Form.Group controlId="otherCondition" className="mb-3">
          <Form.Control
            type="text"
            placeholder="Please list other presenting problems here"
            name="otherCondition"
            value={otherCondition}
            onChange={(e) => setOtherCondition(e.target.value)}
          />
        </Form.Group>
        <h4>
          Reason for Consult/Consult Question <Asterisk />
        </h4>
        <Form.Group controlId="reasonForConsult" className="mb-3">
          <Form.Label>
            Please provide as much detail about your patient and question as
            possible to facilitate efficient communication.
          </Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Enter reason for consult/consult question"
            name="reasonForConsult"
            value={reasonForConsult}
            onChange={(e) => setReasonForConsult(e.target.value)}
            rows={7}
            required
          />
        </Form.Group>
      </>
    );
  };

  return (
    <>
      <h4>Consult Information:</h4>
      {renderConsultForm()}
    </>
  );
};

export default NewConsultForm;
