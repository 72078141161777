import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Button, Row, Col, Container, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PatientInfo from "../PatientInfo";
import ConsultInfo from "../ConsultInfo";
import ConsultReportingForms from "../ConsultReportingForms";
import ConsultSummary from "../ConsultSummary";
import ConsultComments from "../ConsultComments";
import { useAuth } from "../../layout/AuthProvider";
import ConsultAdministration from "../ConsultAdministration";
import Referrals from "../Referrals";
import { pagePrefix } from "../../../utils/pagePrefix";

const Consult = () => {
  const { isLoggedIn } = useAuth();
  const { id } = useParams();
  const [consult, setConsult] = useState(null);
  const [relatedConsults, setRelatedConsults] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [refreshable, setRefreshable] = useState(true);
  const navigate = useNavigate();

  const fetchConsult = () => {
    axios
      .get(`/api/v1/consults/${id}`, { withCredentials: true })
      .then((res) => {
        if (res.status === 200) {
          setConsult(res.data.consult);
          setRelatedConsults(res.data.relatedConsults);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate(`${pagePrefix}/login`);
        } else if (error.response.status === 404) {
          alert("Consult not found");
          navigate(`${pagePrefix}/dashboard`);
        }
        console.error("Failed to get consult", error);
      });
  };

  useEffect(() => {
    fetchConsult();
  }, [id, navigate, refresh]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        (isLoggedIn === "consumer" || isLoggedIn === "consultant") &&
        refreshable &&
        consult.status === "open"
      ) {
        fetchConsult();
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [refreshable, consult, isLoggedIn]);

  const handleCreateNewConsult = (consult) => {
    navigate(`${pagePrefix}/consult/new`, {
      state: {
        patientId: consult.callup_patient.id,
        organizationId: consult.callup_organization.id,
        callerId: consult.createdByRole.callup_user.id,
      },
    });
  };

  if (isLoggedIn === null) {
    return (
      <Container className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  } else if (
    isLoggedIn === "consumer" ||
    isLoggedIn === "consultant" ||
    isLoggedIn === "admin"
  ) {
    return (
      <Container>
        {consult ? (
          <div>
            <Row>
              <Col md={8}>
                <ConsultAdministration
                  consult={consult}
                  relatedConsults={relatedConsults}
                  setRefresh={setRefresh}
                />
              </Col>
              <Col md={4}>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="light"
                    className="mb-3"
                    onClick={() => setRefresh(!refresh)}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-arrows-rotate" />
                  </Button>
                  &nbsp;
                  <Button
                    variant="warning"
                    onClick={() => navigate(`${pagePrefix}/dashboard`)}
                    className="mb-3"
                  >
                    Back to dashboard
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                {consult.status !== "open" ? (
                  <Button
                    variant="success"
                    onClick={() => handleCreateNewConsult(consult)}
                    className="mb-3"
                  >
                    Create a new consult for this patient
                  </Button>
                ) : null}
                <PatientInfo
                  consult={consult}
                  setRefresh={setRefresh}
                  setRefreshable={setRefreshable}
                />
                <ConsultInfo consult={consult} />
                <ConsultReportingForms
                  consult={consult}
                  setRefresh={setRefresh}
                  setRefreshable={setRefreshable}
                />
              </Col>
              <Col md={8}>
                {consult.status === "open" ? (
                  <>
                    <ConsultComments
                      consult={consult}
                      setRefresh={setRefresh}
                      setRefreshable={setRefreshable}
                    />
                    {isLoggedIn === "consumer" &&
                    consult.status !== "closed" ? null : (
                      <div className="border p-2">
                        <ConsultSummary
                          consult={consult}
                          setRefresh={setRefresh}
                          setRefreshable={setRefreshable}
                        />
                        <Referrals
                          consult={consult}
                          setRefresh={setRefresh}
                          setRefreshable={setRefreshable}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="border p-2">
                      <ConsultSummary
                        consult={consult}
                        setRefresh={setRefresh}
                        setRefreshable={setRefreshable}
                      />
                      <Referrals
                        consult={consult}
                        setRefresh={setRefresh}
                        setRefreshable={setRefreshable}
                      />
                    </div>
                    <ConsultComments
                      consult={consult}
                      setRefresh={setRefresh}
                      setRefreshable={setRefreshable}
                    />
                  </>
                )}
              </Col>
            </Row>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </Container>
    );
  } else if (isLoggedIn === "call_center") {
    return <Navigate to={`${pagePrefix}/dashboard`} replace />;
  } else {
    return <Navigate to={`${pagePrefix}/login`} replace />;
  }
};

export default Consult;
