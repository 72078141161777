import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import NewConsultConsumer from "../NewConsultConsumer";
import NewConsultEmployee from "../NewConsultEmployee";
import { useAuth } from "../../layout/AuthProvider";
import { pagePrefix } from "../../../utils/pagePrefix";

const NewConsult = () => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();
  let patientId, organizationId, callerId;
  if (location && location.state) {
    patientId = location.state.patientId;
    organizationId = location.state.organizationId;
    callerId = location.state.callerId;
   }

  if (!isLoggedIn) {
    return <Navigate to={`${pagePrefix}/login`} replace />;
  } else if (isLoggedIn === "consumer") {
    return <NewConsultConsumer patientId={patientId} organizationId={organizationId}/>;
  } else if (isLoggedIn === "consultant" || isLoggedIn === "admin" || isLoggedIn === "call_center") {
    return <NewConsultEmployee patientId={patientId} organizationId={organizationId} callerId={callerId}/>;
  }
};

export default NewConsult;
