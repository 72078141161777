import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Form, Button, Container, Row, Col } from "react-bootstrap";

import OrgRegistration from "../../Orgs/OrgRegistration";
import UserCredentials from "../../utils/UserCredentials";
import UserRole from "../../utils/UserRole";
import { pagePrefix } from "../../../utils/pagePrefix";
import { Asterisk } from "../../utils/Asterisk";

const UserRegisterAdmin = () => {
  const [organizations, setOrganizations] = useState([]);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [type, setType] = useState("consumer");
  const [subtype, setSubtype] = useState();
  const [credential, setCredential] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [roleType, setRoleType] = useState();
  const [organizationId, setOrganizationId] = useState();
  const [organizationName, setOrganizationName] = useState();
  const [organizationType, setOrganizationType] = useState();
  const [organizationAddressLine1, setOrganizationAddressLine1] = useState();
  const [organizationAddressLine2, setOrganizationAddressLine2] = useState();
  const [organizationCity, setOrganizationCity] = useState();
  const [organizationCounty, setOrganizationCounty] = useState();
  const [organizationState, setOrganizationState] = useState("UT");
  const [organizationZip, setOrganizationZip] = useState();
  const [organizationPhone, setOrganizationPhone] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("/api/v1/organizations")
      .then((res) => {
        if (res.data) {
          setOrganizations(res.data);
          if (res.data.length === 0) {
            setOrganizationId("0");
          }
        }
      })
      .catch((err) => {
        setOrganizationId("0");
        console.error(err);
      });
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      firstName,
      lastName,
      type,
      subtype,
      credential,
      email,
      phoneNumber,
      roleType,
      organizationId,
      organizationName,
      organizationType,
      organizationAddressLine1,
      organizationAddressLine2,
      organizationCity,
      organizationCounty,
      organizationState,
      organizationZip,
      organizationPhone,
    };
    try {
      const res = await axios.post("/api/v1/users/admin", formData);
      if (res.status === 200) {
        navigate(`${pagePrefix}/admin/users`);
      }
    } catch (err) {
      const errorMessage = err.response.data.errors[0].msg;
      alert(errorMessage);
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <h1 className="large text-primary">Register User</h1>
          <Form onSubmit={onSubmit}>
            <h4>
              User Information: <Asterisk />
            </h4>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="First Name"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="email"
                placeholder="Email Address"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Phone Number"
                name="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                minLength={10}
                maxLength={22}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Type</Form.Label>
              <Form.Select
                name="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
                required
              >
                <option value="consumer">Consumer</option>
                <option value="admin">Admin</option>
                <option value="consultant">Consultant</option>
                <option value="call_center">Call Center</option>
              </Form.Select>
            </Form.Group>
            {type === "consultant" ? (
              <Form.Group className="mb-3">
                <Form.Label>Subtype</Form.Label>
                <Form.Select
                  name="subtype"
                  value={subtype}
                  onChange={(e) => setSubtype(e.target.value)}
                >
                  <option value="">
                    Please select a subtype for this consultant
                  </option>
                  <option value="psychiatrist">Psychiatrist</option>
                  <option value="care coordinator">Care Coordinator</option>
                </Form.Select>
              </Form.Group>
            ) : null}
            <UserCredentials
              credential={[credential, setCredential]}
              required={type === "consumer" ? true : false}
            />
            {type === "consumer" ? (
              <div>
                <UserRole roleType={[roleType, setRoleType]} />
                <OrgRegistration
                  organizations={organizations}
                  organizationId={[organizationId, setOrganizationId]}
                  organizationName={[organizationName, setOrganizationName]}
                  organizationType={[organizationType, setOrganizationType]}
                  organizationAddressLine1={[
                    organizationAddressLine1,
                    setOrganizationAddressLine1,
                  ]}
                  organizationAddressLine2={[
                    organizationAddressLine2,
                    setOrganizationAddressLine2,
                  ]}
                  organizationCounty={[
                    organizationCounty,
                    setOrganizationCounty,
                  ]}
                  organizationCity={[organizationCity, setOrganizationCity]}
                  organizationState={[organizationState, setOrganizationState]}
                  organizationZip={[organizationZip, setOrganizationZip]}
                  organizationPhone={[organizationPhone, setOrganizationPhone]}
                />
              </div>
            ) : null}
            <Button variant="primary" type="submit">
              Register
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default UserRegisterAdmin;
