import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { Form, Button, Container, Row, Col } from "react-bootstrap";

import OrgRegistration from "../Orgs/OrgRegistration";
import PasswordForm from "../utils/PasswordForm";
import UserCredentials from "../utils/UserCredentials";
import UserRole from "../utils/UserRole";
import { pagePrefix } from "../../utils/pagePrefix";
import { Asterisk } from "../utils/Asterisk";
import { useAuth } from "../layout/AuthProvider";

const Register = () => {
  const { setLoginState } = useAuth();
  const [organizations, setOrganizations] = useState([]);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [credential, setCredential] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [roleType, setRoleType] = useState();
  const [organizationId, setOrganizationId] = useState();
  const [organizationName, setOrganizationName] = useState();
  const [organizationType, setOrganizationType] = useState();
  const [organizationAddressLine1, setOrganizationAddressLine1] = useState();
  const [organizationAddressLine2, setOrganizationAddressLine2] = useState();
  const [organizationCity, setOrganizationCity] = useState();
  const [organizationState, setOrganizationState] = useState("UT");
  const [organizationCounty, setOrganizationCounty] = useState();
  const [organizationZip, setOrganizationZip] = useState();
  const [organizationPhone, setOrganizationPhone] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("/api/v1/organizations")
      .then((res) => {
        if (res.data) {
          setOrganizations(res.data);
          if (res.data.length === 0) {
            setOrganizationId("0");
          }
        }
      })
      .catch((err) => {
        setOrganizationId("0");
        console.error(err);
      });
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      alert("Your passwords do not match");
    } else {
      const formData = {
        firstName,
        lastName,
        credential,
        email,
        password,
        phoneNumber,
        roleType,
        organizationId,
        organizationName,
        organizationType,
        organizationAddressLine1,
        organizationAddressLine2,
        organizationCity,
        organizationState,
        organizationZip,
        organizationCounty,
        organizationPhone,
      };
      try {
        const res = await axios.post("/api/v1/users", formData);
        if (res.data.user) {
          setLoginState(true);
          navigate(`${pagePrefix}/dashboard`);
        }
      } catch (err) {
        const errorMessage = err.response.data.errors[0].msg;
        alert(errorMessage);
      }
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <h1 className="large text-primary">Sign Up</h1>
          <p className="lead">
            <i className="fas fa-user"></i> Create Your Account (Already have an
            account? <Link to={`${pagePrefix}/login`}>Sign In</Link>)
          </p>
          <Form onSubmit={onSubmit}>
            <h4>
              Consult Requester Information: <Asterisk />
            </h4>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="First Name"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="email"
                placeholder="Email Address"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <PasswordForm
              password={[password, setPassword]}
              password2={[password2, setPassword2]}
            />
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Phone Number"
                name="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                minLength={10}
                maxLength={22}
              />
            </Form.Group>
            <UserCredentials credential={[credential, setCredential]} />
            <UserRole roleType={[roleType, setRoleType]} />
            <OrgRegistration
              organizations={organizations}
              organizationId={[organizationId, setOrganizationId]}
              organizationName={[organizationName, setOrganizationName]}
              organizationType={[organizationType, setOrganizationType]}
              organizationAddressLine1={[
                organizationAddressLine1,
                setOrganizationAddressLine1,
              ]}
              organizationAddressLine2={[
                organizationAddressLine2,
                setOrganizationAddressLine2,
              ]}
              organizationCity={[organizationCity, setOrganizationCity]}
              organizationState={[organizationState, setOrganizationState]}
              organizationZip={[organizationZip, setOrganizationZip]}
              organizationCounty={[organizationCounty, setOrganizationCounty]}
              organizationPhone={[organizationPhone, setOrganizationPhone]}
            />
            <Button variant="primary" type="submit">
              Register
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
