import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faCircle,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";

import { AuthProvider } from "./components/layout/AuthProvider";
import CustomNavbar from "./components/layout/Navbar";
import Login from "./components/auth/Login";
import Dashboard from "./components/Dashboard/pages/Dashboard";
import Register from "./components/auth/Register";
import NewConsult from "./components/Consult/pages/NewConsult";
import { Container } from "react-bootstrap";
import Consult from "./components/Consult/pages/Consult";
import OldConsults from "./components/Consult/pages/OldConsults";
import Events from "./components/Events/pages/Events";
import UsersAdmin from "./components/Users/pages/UsersAdmin";
import OrgsAdmin from "./components/Orgs/pages/OrgsAdmin";
import UserRegisterAdmin from "./components/Users/pages/UserRegisterAdmin";
import OrgRegisterAdmin from "./components/Orgs/pages/OrgRegisterAdmin";
import Profile from "./components/Profile/pages/Profile";
import InitiatePasswordReset from "./components/Users/pages/InitiatePasswordReset";
import PasswordReset from "./components/Users/pages/PasswordReset";
import { pagePrefix } from "./utils/pagePrefix";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

library.add(faCheck, faCircle, faArrowsRotate);

const App = () => (
  <AuthProvider>
    <div className="pb-4">
      <Router>
        <CustomNavbar />
        <Container className="pt-4 small">
          <Routes>
            <Route path={`${pagePrefix}`} element={<Register />} />
            <Route path={`${pagePrefix}/register`} element={<Register />} />
            <Route path={`${pagePrefix}/login`} element={<Login />} />
            <Route path={`${pagePrefix}/dashboard`} element={<Dashboard />} />
            <Route path={`${pagePrefix}/profile`} element={<Profile />} />
            <Route
              path={`${pagePrefix}/consult/new`}
              element={<NewConsult />}
            />
            <Route path={`${pagePrefix}/consult/:id`} element={<Consult />} />
            <Route
              path={`${pagePrefix}/consults/old`}
              element={<OldConsults />}
            />
            <Route path={`${pagePrefix}/admin/events`} element={<Events />} />
            <Route
              path={`${pagePrefix}/admin/users`}
              element={<UsersAdmin />}
            />
            <Route
              path={`${pagePrefix}/admin/register/user`}
              element={<UserRegisterAdmin />}
            />
            <Route
              path={`${pagePrefix}/admin/register/organization`}
              element={<OrgRegisterAdmin />}
            />
            <Route
              path={`${pagePrefix}/admin/organizations`}
              element={<OrgsAdmin />}
            />
            <Route
              path={`${pagePrefix}/password/reset/initiate`}
              element={<InitiatePasswordReset />}
            />
            <Route
              path={`${pagePrefix}/password/reset/:token`}
              element={<PasswordReset />}
            />
          </Routes>
        </Container>
      </Router>
    </div>
  </AuthProvider>
);

export default App;
