import React, { useState } from "react";
import axios from "axios";
import { Button, Form, Modal, Table } from "react-bootstrap";

import { useAuth } from "../layout/AuthProvider";
import { toBirthDay } from "../../utils/toBirthDay";

const PatientInfo = (props) => {
  const { isLoggedIn } = useAuth();
  const consult = props.consult;
  const setRefresh = props.setRefresh;
  const setRefreshable = props.setRefreshable;
  const [isEditing, setIsEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [patientInfo, setPatientInfo] = useState({
    firstName: consult.callup_patient.firstName,
    lastName: consult.callup_patient.lastName,
    DOB: consult.callup_patient.DOB,
    age: consult.callup_patient.age,
    email: consult.callup_patient.email,
    phone: consult.callup_patient.phone,
    contactConsent: consult.callup_patient.contactConsent,
  });

  const showConsentText = (consent) => (consent ? "Yes" : "No");

  const handleHide = () => {
    setShowModal(false);
  };

  const handleEditToggle = () => {
    const currentIsEditing = isEditing;
    setIsEditing(!currentIsEditing);
    setRefreshable(currentIsEditing);
  };

  const handleChange = (e) => {
    if (e.target.name === "contactConsent") {
      setPatientInfo({
        ...patientInfo,
        [e.target.name]: !patientInfo.contactConsent,
      });
      return;
    }
    setPatientInfo({ ...patientInfo, [e.target.name]: e.target.value });
  };

  const handleAge = (e) => {
    const dob = e.target.value;
    const today = new Date();
    const birthDate = new Date(dob);
    let a = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      a--;
    }
    setPatientInfo({ ...patientInfo, DOB: dob, age: a });
  };

  const savePatientInfo = async () => {
    if (
      !patientInfo.firstName ||
      !patientInfo.lastName ||
      !patientInfo.DOB ||
      !patientInfo.email ||
      !patientInfo.phone ||
      patientInfo.firstName.length < 1 ||
      patientInfo.lastName.length < 1 ||
      patientInfo.DOB.length < 1
    ) {
      console.error("Invalid patient info");
      alert("Invalid patient info");
      return;
    }
    try {
      const response = await axios.post(
        `/api/v1/consults/${consult.id}/patient`,
        patientInfo
      );
      if (response.status === 200) {
        setRefresh((prev) => !prev);
        setShowModal(false);
        setIsEditing(false);
        setRefreshable(true);
      } else {
        console.error("Failed to save patient info");
      }
    } catch (error) {
      console.error("Error saving patient info:", error);
    }
  };

  const renderModal = () => {
    return (
      <Modal show={showModal} onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title>Save Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to save changes to this patient?</p>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Field</th>
                <th>Original Value</th>
                <th>New Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>First Name</td>
                <td>{consult.callup_patient.firstName}</td>
                <td>{patientInfo.firstName}</td>
              </tr>
              <tr>
                <td>Last Name</td>
                <td>{consult.callup_patient.lastName}</td>
                <td>{patientInfo.lastName}</td>
              </tr>
              <tr>
                <td>Date of Birth</td>
                <td>
                  {consult.callup_patient.DOB
                    ? toBirthDay(consult.callup_patient.DOB)
                    : `NA`}
                </td>
                <td>
                  {patientInfo.DOB
                    ? toBirthDay(patientInfo.DOB)
                    : `NA (Please provide a DOB)`}
                </td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{consult.callup_patient.email}</td>
                <td>{patientInfo.email}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>{consult.callup_patient.phone}</td>
                <td>{patientInfo.phone}</td>
              </tr>
              <tr>
                <td>Contact Consent</td>
                <td>
                  {showConsentText(consult.callup_patient.contactConsent)}
                </td>
                <td>{showConsentText(patientInfo.contactConsent)}</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHide}>
            Cancel
          </Button>
          <Button variant="primary" onClick={savePatientInfo}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className="border p-2">
      <h4>Patient Information</h4>
      {isEditing ? (
        <>
          <Form>
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                name="firstName"
                value={patientInfo.firstName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                name="lastName"
                value={patientInfo.lastName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formDOB">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                name="DOB"
                type="date"
                value={patientInfo.DOB}
                onChange={handleAge}
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                value={patientInfo.email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                name="phone"
                value={patientInfo.phone}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formContactConsent" className="mt-3">
              <Form.Check
                type="checkbox"
                label="Consent for contact obtained?"
                name="contactConsent"
                checked={patientInfo.contactConsent}
                onChange={handleChange}
              />
            </Form.Group>
            <Button
              className="mt-2 mb-2"
              variant="primary"
              onClick={() => setShowModal(true)}
            >
              Save
            </Button>
          </Form>
          {renderModal()}
        </>
      ) : (
        <>
          <p>
            Name: {patientInfo.firstName} {patientInfo.lastName}
          </p>
          <p>Age: {patientInfo.age}</p>
          {patientInfo.DOB ? <p>DOB: {toBirthDay(patientInfo.DOB)}</p> : ""}
          <p>Email: {patientInfo.email}</p>
          <p>Phone: {patientInfo.phone}</p>
          <p>Contact Consent: {showConsentText(patientInfo.contactConsent)}</p>
          {isLoggedIn === "admin" || isLoggedIn === "consultant" ? (
            <Button variant="link" onClick={handleEditToggle}>
              Edit
            </Button>
          ) : null}
        </>
      )}
    </div>
  );
};

export default PatientInfo;
