import React from "react";

const ConsultTimeSpentAdmin = (props) => {
  const timeSpent = props.timeSpent;

  return (
    <>
      <h5>Time Spent:</h5>
      <p>{timeSpent}</p>
    </>
  );
};

export default ConsultTimeSpentAdmin;
