import React from "react";

import { useAuth } from "../layout/AuthProvider";
import CallAttempts from "./CallAttempts";
import ConsultServicesProvidedAdmin from "./ConsultServicesProvidedAdmin";
import ConsultTimeSpentAdmin from "./ConsultTimeSpentAdmin";

const ConsultReportingForms = (props) => {
  const { isLoggedIn } = useAuth();
  const consult = props.consult;
  const setRefresh = props.setRefresh;
  const setRefreshable = props.setRefreshable;

  if (isLoggedIn === "consultant" || isLoggedIn === "admin") {
    return (
      <div className="border p-2">
        <h4>Reporting Forms</h4>
        <CallAttempts
          consult={consult}
          setRefresh={setRefresh}
          setRefreshable={setRefreshable}
        />

        {isLoggedIn === "consultant" ? null : (
          <div>
            <hr />
            <ConsultServicesProvidedAdmin
              diagnosticClarification={consult.diagnosticClarification}
              medicationAdjustment={consult.medicationAdjustment}
              treatmentPlanning={consult.treatmentPlanning}
              crisisInterventionRecommendations={
                consult.crisisInterventionRecommendations
              }
              otherServiceProvided={consult.otherServiceProvided}
            />
            <hr />
            <ConsultTimeSpentAdmin timeSpent={consult.timeSpent} />
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default ConsultReportingForms;
